const Help = () => {
    return <section className="help p-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2>We're here to help</h2>
                        <p>Monday-Friday: 10am-11pm ET</p>
                        <p>Sunday: 12pm-8pm ET</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 text-center help-footer">
                        <ul>
                            <li>
                                <div className="help-box">
                                    <figure>
                                        <img src="/img/c-1.png" />
                                    </figure>
                                    <h4>Contact Us</h4>
                                    <div className="circle">
                                        <i class="ri-add-line"></i>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="help-box">
                                    <figure>
                                        <img src="/img/c-3.png" />
                                    </figure>
                                    <h4>Product Care</h4>
                                    <div className="circle">
                                        <i class="ri-add-line"></i>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="help-box">
                                    <figure>
                                        <img src="/img/c-2.png" />
                                    </figure>
                                    <h4>Design Concierge</h4>
                                    <div className="circle">
                                        <i class="ri-add-line"></i>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="help-box">
                                    <figure>
                                        <img src="/img/c-4.png" />
                                    </figure>
                                    <h4>Shipping + Return</h4>
                                    <div className="circle">
                                        <i class="ri-add-line"></i>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="help-box">
                                    <figure>
                                        <img src="/img/c-5.png" />
                                    </figure>
                                    <h4>Visit a Studio</h4>
                                    <div className="circle">
                                        <i class="ri-add-line"></i>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    
}

export default Help;