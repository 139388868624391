import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProductBox from "../../Components/Elements/Product_box"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { ApiService } from "../../Components/Services/apiservices";
import React, { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import { BrowserView, MobileView } from "react-device-detect";


const ProductListing = () => {
    const { type, slug } = useParams();
    const location= useLocation()
   const shopalltype=location.pathname.replace('/', '')
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    const [hasMore, sethasMore] = useState(false)
    const [PageNo, setPageNo] = useState(1);
    const [selectedAttributes, setSelectedAttributes] = useState({});
    const [selectedSort, setSelectedSort] = useState('');
    const [resProductData, setProductData] = useState([])
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setisLoading] = useState(false);
    const [headerdetail, setheaderdetail] = useState()
    const [attributesData, setAttributesData] = useState([])
    const [Productperpg, setProductperpg] = useState();
    const [TotalProduct, setTotalProduct] = useState();
    const [fieldShowStatus, setFieldShowStatus] = useState({
        filtershow: '',
        breadcrumbshow: '',
        type: ''
    })

    useEffect(() => {
        if (didMountRef.current) {
            getCategoryDetail()
            getProductListing(null)
        }
        didMountRef.current = false;
    })

    const getProductListing = (sort = null) => {
        setSelectedSort(sort)
        let pageNumber = PageNo;
        if (sort) {
            setPageNo(1);
            pageNumber = 1;
        }
        setisLoading(true)
        const dataString = {
            filterArray: selectedAttributes,
            sort: sort ? sort : selectedSort ? selectedSort : '',
            slug: slug ? slug : '',
            type: slug === "all" ? '' : type? type:shopalltype?shopalltype:'',
        };
        ApiService.postData(`product-list?page=${pageNumber}`, dataString).then((res) => {
            if (res.status == "success") {

                if (pageNumber >= res.resProductsData.last_page) {
                    sethasMore(false);
                } else {
                    sethasMore(true);
                    setPageNo(pageNumber + 1);
                }
                if (sort !== '' && sort !== null) {
                    setProductData(res.resProductsData.data);
                } else if (res.resProductsData.data.length > 0) {
                    const mergeData = [...resProductData, ...res.resProductsData.data];
                    setProductData(mergeData);
                }
                setProductperpg(res.resProductsData.per_page)
                setTotalProduct(res.resProductsData.total)
                setisLoading(false)
            } else {
                setisLoading(false)
            }
        }).catch(() => { setisLoading(false) })
    }

    const getCategoryDetail = () => {
        var dataString={}
        if(type=='category'||type=='collection'){
             dataString = {
                type: type?type:shopalltype?shopalltype:"",
                cat_slug: slug ? slug : '',
            };
        }
        else if(type=='tag'){
            dataString = {
                type: type?type:shopalltype?shopalltype:"",
                tag_slug: slug ? slug : '',
            };
        }
        else{
             dataString = {
                type: type?type:shopalltype?shopalltype:"",
            };
        }
       
        ApiService.postData("categorydetails", dataString).then((res) => {
            if (res.status === "success") {
                setheaderdetail(res.aryHeaderDetails)
                setAttributesData(res.resAttributesData)

            }
        }).catch(() => { })
    }
    return (<>
   

        <BrowserView>
        <Header></Header>
        {headerdetail && (headerdetail.showTitle == 1 || headerdetail.showDescr == 1) ? 
        <div className="subheader">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-8">
                        {headerdetail && headerdetail.showTitle == 1 ? <h1>{headerdetail.title}</h1> : ""}
                        {headerdetail && headerdetail.showDescr == 1 ? <p dangerouslySetInnerHTML={{ __html: headerdetail.desc }}></p> : ''}
                    </div>
                </div>
            </div>
        </div> : ''}

        <div className="listing-filter">
            <div className="listing-filter-top">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="filter-form">
                                <label>Colour</label>
                                <div className="filter-form-select">
                                    <select id="colour_group" name="colour_group">
                                        <option value="">All Colours</option>
                                        <option value="blue">Blue</option>
                                        <option value="brown">Brown</option>
                                        <option value="cream">Cream</option>
                                        <option value="green">Green</option>
                                        <option value="grey">Grey</option>
                                        <option value="mixed">Mixed</option>
                                        <option value="multi">Multi</option>
                                        <option value="natural">Natural</option>
                                        <option value="white">White</option>
                                    </select>
                                    <div className="select-value">
                                        All Colours <i class="ri-arrow-down-s-line ri-xl"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="filter-form">
                                <label>Colour</label>
                                <div className="filter-form-select">
                                    <select id="colour_group" name="colour_group">
                                        <option value="">All Colours</option>
                                        <option value="blue">Blue</option>
                                        <option value="brown">Brown</option>
                                        <option value="cream">Cream</option>
                                        <option value="green">Green</option>
                                        <option value="grey">Grey</option>
                                        <option value="mixed">Mixed</option>
                                        <option value="multi">Multi</option>
                                        <option value="natural">Natural</option>
                                        <option value="white">White</option>
                                    </select>
                                    <div className="select-value">
                                        All Colours <i class="ri-arrow-down-s-line ri-xl"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="filter-form">
                                <label>Colour</label>
                                <div className="filter-form-select">
                                    <select id="colour_group" name="colour_group">
                                        <option value="">All Colours</option>
                                        <option value="blue">Blue</option>
                                        <option value="brown">Brown</option>
                                        <option value="cream">Cream</option>
                                        <option value="green">Green</option>
                                        <option value="grey">Grey</option>
                                        <option value="mixed">Mixed</option>
                                        <option value="multi">Multi</option>
                                        <option value="natural">Natural</option>
                                        <option value="white">White</option>
                                    </select>
                                    <div className="select-value">
                                        All Colours <i class="ri-arrow-down-s-line ri-xl"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="filter-form">
                                <label>Colour</label>
                                <div className="filter-form-select">
                                    <select id="colour_group" name="colour_group">
                                        <option value="">All Colours</option>
                                        <option value="blue">Blue</option>
                                        <option value="brown">Brown</option>
                                        <option value="cream">Cream</option>
                                        <option value="green">Green</option>
                                        <option value="grey">Grey</option>
                                        <option value="mixed">Mixed</option>
                                        <option value="multi">Multi</option>
                                        <option value="natural">Natural</option>
                                        <option value="white">White</option>
                                    </select>
                                    <div className="select-value">
                                        All Colours <i class="ri-arrow-down-s-line ri-xl"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="listing-filter-bottom">
                <div className="container-fluid">
                    <div className="row align-items-center justify-contant-between">
                        <div className="col-lg-4">
                            <p className="mb-0">{resProductData.length > 0 ? 1 : 0} {resProductData?.length > 0 ? '-' : ''} {resProductData?.length > 0 ? resProductData?.length : ''} of {TotalProduct} results</p>
                        </div>
                        <div className="col-lg-4">
                            <ul className="vpagination">
                                <li>View</li>
                                <li><a href="#" className="active">{resProductData?.length}</a></li>
                                <li><a href="#">{TotalProduct}</a></li>

                            </ul>
                        </div>
                        <div className="col-lg-4 justify-content-end d-flex">
                            <div className="sortby">
                                Sort:<select>Bestseller</select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <InfiniteScroll
            dataLength={resProductData.length}
            next={getProductListing}
            hasMore={hasMore}
            style={{ overflow: "hidden !important" }}

        >
            {isLoading ? <>
                <section className="sectionbottom-gap-medium">
                    <div className="container-fluid">
                        <div className="row">
            {  Array(8).fill().map((_, index) => (
          <div className="col-lg-3" key={index}>
            <div className="product text-center product-style-1">
              <div className="product-media-shap">
                <figure className="product-media">
                  <Skeleton height={450} width={'100%'} />
                </figure>
              </div>
              <div className="product-details">
                <h2 className="product-name">
                  <Skeleton width={'80%'} />
                </h2>
                <div className="product-price">
                  <Skeleton width={'150px'} />
                </div>
              </div>
            </div>
          </div>
        ))}
        </div>
        </div>
        </section>
            
            </> : <>
                <section className="sectionbottom-gap-medium">
                    <div className="container-fluid">
                        <div className="row g-3">
                            {resProductData.length > 0 ? <>
                                {resProductData && resProductData.map((item, index) => {
                                    return (<>
                                        <div className="col-lg-3">
                                            <ProductBox productValue={item} classType="product tx-center" key={index} home_product_style={2}></ProductBox>
                                        </div>

                                    </>)

                                })}
                            </> : <>
                                <h2 className="text-center">No Product Found</h2>
                            </>}

                        </div>
                    </div>
                </section>
            </>}


        </InfiniteScroll>

        <Footer></Footer>
        
        
        </BrowserView>

        <MobileView>
        <Header></Header>
        {headerdetail && (headerdetail.showTitle == 1 || headerdetail.showDescr == 1) ? 
        <div className="subheader">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-8">
                        {headerdetail && headerdetail.showTitle == 1 ? <h1>{headerdetail.title}</h1> : ""}
                        {headerdetail && headerdetail.showDescr == 1 ? <p dangerouslySetInnerHTML={{ __html: headerdetail.desc }}></p> : ''}
                    </div>
                </div>
            </div>
        </div> : ''}

        {/* <div className="listing-filter">
            <div className="listing-filter-top">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="filter-form">
                                <label>Colour</label>
                                <div className="filter-form-select">
                                    <select id="colour_group" name="colour_group">
                                        <option value="">All Colours</option>
                                        <option value="blue">Blue</option>
                                        <option value="brown">Brown</option>
                                        <option value="cream">Cream</option>
                                        <option value="green">Green</option>
                                        <option value="grey">Grey</option>
                                        <option value="mixed">Mixed</option>
                                        <option value="multi">Multi</option>
                                        <option value="natural">Natural</option>
                                        <option value="white">White</option>
                                    </select>
                                    <div className="select-value">
                                        All Colours <i class="ri-arrow-down-s-line ri-xl"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="filter-form">
                                <label>Colour</label>
                                <div className="filter-form-select">
                                    <select id="colour_group" name="colour_group">
                                        <option value="">All Colours</option>
                                        <option value="blue">Blue</option>
                                        <option value="brown">Brown</option>
                                        <option value="cream">Cream</option>
                                        <option value="green">Green</option>
                                        <option value="grey">Grey</option>
                                        <option value="mixed">Mixed</option>
                                        <option value="multi">Multi</option>
                                        <option value="natural">Natural</option>
                                        <option value="white">White</option>
                                    </select>
                                    <div className="select-value">
                                        All Colours <i class="ri-arrow-down-s-line ri-xl"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="filter-form">
                                <label>Colour</label>
                                <div className="filter-form-select">
                                    <select id="colour_group" name="colour_group">
                                        <option value="">All Colours</option>
                                        <option value="blue">Blue</option>
                                        <option value="brown">Brown</option>
                                        <option value="cream">Cream</option>
                                        <option value="green">Green</option>
                                        <option value="grey">Grey</option>
                                        <option value="mixed">Mixed</option>
                                        <option value="multi">Multi</option>
                                        <option value="natural">Natural</option>
                                        <option value="white">White</option>
                                    </select>
                                    <div className="select-value">
                                        All Colours <i class="ri-arrow-down-s-line ri-xl"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="filter-form">
                                <label>Colour</label>
                                <div className="filter-form-select">
                                    <select id="colour_group" name="colour_group">
                                        <option value="">All Colours</option>
                                        <option value="blue">Blue</option>
                                        <option value="brown">Brown</option>
                                        <option value="cream">Cream</option>
                                        <option value="green">Green</option>
                                        <option value="grey">Grey</option>
                                        <option value="mixed">Mixed</option>
                                        <option value="multi">Multi</option>
                                        <option value="natural">Natural</option>
                                        <option value="white">White</option>
                                    </select>
                                    <div className="select-value">
                                        All Colours <i class="ri-arrow-down-s-line ri-xl"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="listing-filter-bottom">
                <div className="container-fluid">
                    <div className="row align-items-center justify-contant-between">
                        <div className="col-lg-4">
                            <p className="mb-0">{resProductData.length > 0 ? 1 : 0} {resProductData?.length > 0 ? '-' : ''} {resProductData?.length > 0 ? resProductData?.length : ''} of {TotalProduct} results</p>
                        </div>
                        <div className="col-lg-4">
                            <ul className="vpagination">
                                <li>View</li>
                                <li><a href="#" className="active">{resProductData?.length}</a></li>
                                <li><a href="#">{TotalProduct}</a></li>

                            </ul>
                        </div>
                        <div className="col-lg-4 justify-content-end d-flex">
                            <div className="sortby">
                                Sort:<select>Bestseller</select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        <InfiniteScroll
            dataLength={resProductData.length}
            next={getProductListing}
            hasMore={hasMore}
            style={{ overflow: "hidden !important" }}

        >
            {isLoading ? <>
                <section className="sectionbottom-gap-medium">
                    <div className="container-fluid">
                        <div className="row">
            {  Array(8).fill().map((_, index) => (
          <div className="col-lg-3" key={index}>
            <div className="product text-center product-style-1">
              <div className="product-media-shap">
                <figure className="product-media">
                  <Skeleton height={450} width={'100%'} />
                </figure>
              </div>
              <div className="product-details">
                <h2 className="product-name">
                  <Skeleton width={'80%'} />
                </h2>
                <div className="product-price">
                  <Skeleton width={'150px'} />
                </div>
              </div>
            </div>
          </div>
        ))}
        </div>
        </div>
        </section>
            
            </> : <>
                <section className="sectionbottom-gap-medium">
                    <div className="container-fluid">
                        <div className="row g-3">
                            {resProductData.length > 0 ? <>
                                {resProductData && resProductData.map((item, index) => {
                                    return (<>
                                        <div className="col-lg-3 col-6">
                                            <ProductBox productValue={item} classType="product tx-center" key={index} home_product_style={2}></ProductBox>
                                        </div>

                                    </>)

                                })}
                            </> : <>
                                <h2 className="text-center">No Product Found</h2>
                            </>}

                        </div>
                    </div>
                </section>
            </>}


        </InfiniteScroll>

        <Footer></Footer>
        </MobileView>

    </>)
}

export default ProductListing