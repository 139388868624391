import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../Components/Services/apiservices";
import DataContext from "../../Components/Elements/context";

const AddressFrom = () => {
    const didMountRef = useRef(true);
    const Navigate = useNavigate();
    const contextValues = useContext(DataContext)
    const [countryData, setCountryData]= useState([])

    useEffect(() => {
        if (didMountRef.current) {
            getCountryCodedata()
            getStateData()
        }
        didMountRef.current = false;
    }, [contextValues]);

    const getCountryCodedata = () => {
        ApiService.fetchData('getCountryData').then((res) => {
            if (res.status == 'success') {
                setCountryData(res.data)
            }
        }).catch((error) => {

        })
    }
    const [cityData, setcityData] = useState([]);
    const [stateData, setstateData] = useState([]);
    const onTodoRegChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        contextValues.setUserAddressDetails((prevState) => ({
            ...prevState,
            [name]: newValue,
        }));
        console.log(newValue ,name, 'newValue')
        if (name === 'ua_country_id' && newValue !== '') {
            const selectedCountry = countryData.find(item => item.country_id == newValue);
            
            if (selectedCountry) {
                contextValues.setUserAddressDetails((prevState) => ({
                    ...prevState,
                    ua_country_id: newValue,
                    ua_country_name: selectedCountry.country_name,
                }));
            }
        }
        if (name == 'ua_state_id' && newValue) {
            const selectedState = stateData.find(item => Number(item.state_id) == Number(newValue));
            if (selectedState) {
                contextValues.setUserAddressDetails((prevState) => ({
                    ...prevState,
                    ua_state_id: newValue,
                    ua_state_name: selectedState.state_name,
                    ua_city_id: 0,
                }));
            }else{
                contextValues.setUserAddressDetails((prevState) => ({
                    ...prevState,
                    ua_city_id: 0,
                }));
            }
            getCityData(value)
        }
        if (name == 'ua_city_id' && newValue) {
            const selectedCity = cityData.find(item => Number(item.cities_id) == Number(newValue));
            if (selectedCity) {
                contextValues.setUserAddressDetails((prevState) => ({
                    ...prevState,
                    ua_city_id: newValue,
                    ua_city_name: selectedCity.cities_name,
                }));
            }
        }
    }
    const getStateData = () => {
        ApiService.fetchData("getallStates").then((res) => {
            if (res.status == "success") {
                setstateData(res.data);
            }
        }).catch(()=>{

        });
    };
    const getCityData = (stateid) => {
        const dataString = {
            state_id: stateid
        }
        ApiService.postData("getCityByState", dataString).then((res) => {
            if (res) {
                setcityData(res);
            }
        }).catch(()=>{});
    };
    return (
        <>
            <div className="row g-3 mb-20 mt-20">
                <div className="col-lg-12">
                    <div className="cartSectionTitle">
                        <h5 className="mb-0 tx-18">Shipping Details</h5>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="flotting-group mb-10">
                        <label >First Name</label>
                        <input type="text" className={` required form-control`}
                            name='ua_fname' placeholder="First Name" onChange={(e) => onTodoRegChange(e)}
                        ></input>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="flotting-group mb-10">
                        <label >Last Name</label>
                        <input type="text" className="required form-control" placeholder="Last Name" name='ua_lname'
                            onChange={(e) => onTodoRegChange(e)}></input>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="flotting-group mb-10">
                        <label >Mobile Number</label>
                        <input type="number" className="required form-control" name="ua_mobile" placeholder="Mobile Number"
                            onChange={(e) => onTodoRegChange(e)}
                           ></input>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="flotting-group mb-10">
                        <label >House No, Appartment , Suite etc</label>
                        <input type="text" className="required form-control" name="ua_apartment"
                            placeholder="House No, Appartment , Suite etc"
                            onChange={(e) => onTodoRegChange(e)}></input>
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="flotting-group mb-10">
                        <label >Street Address</label>
                        <input type="text" className="required form-control" name="ua_complete_address"
                            placeholder="Street Address"
                            onChange={(e) => onTodoRegChange(e)}
                            ></input>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="flotting-group mb-10">
                        <label >Postal Code</label>
                        <input type="number" className="required form-control"
                            style={{
                                appearance: 'textfield',      // Firefox
                                MozAppearance: 'textfield',   // Firefox (alternative)
                                WebkitAppearance: 'none',     // Chrome, Safari, Edge, Opera
                            }}
                            placeholder="Postal Code" name="ua_pincode"
                            onChange={(e) => onTodoRegChange(e)}
                            ></input>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="flotting-group mb-10">
                        <label >Country</label>
                        <select onChange={(e) => { onTodoRegChange(e) }}
                            name='ua_country_id' value={contextValues.userAddressDetails.country_id} className='required form-control'>
                            <option value=''>Select Country</option>
                            {countryData && countryData.map((items, index) => {
                                return (<><option value={items.country_id} key={index}>{items.country_name}</option></>)
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="flotting-group mb-10">
                        <label >State</label>
                        <select value={contextValues.userAddressDetails.ua_state_id} onChange={(e) => onTodoRegChange(e)}
                            name='ua_state_id' className='required form-control'>
                            <option value=''>Select State</option>
                            {stateData.length > 0 && stateData.map((value) => (
                                <option value={value.state_id}>{value.state_name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="flotting-group mb-10">
                        <label >City</label>
                        <select value={contextValues.userAddressDetails.ua_city_id} onChange={(e) => onTodoRegChange(e)}
                            name='ua_city_id' className='required form-control'>
                            <option value=''>Select City</option>
                            {cityData.length > 0 && cityData.map((value) => (
                                <option value={value.cities_id}>{value.cities_name}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className={contextValues.userAddressDetails.ua_address_type === 'Other' ? "col-lg-6" : "col-lg-12"}>
                    <div className="flotting-group mb-10">
                        <label >Address Type</label>
                        <select name="ua_address_type" className="form-control required" value={contextValues.userAddressDetails.ua_address_type}
                            onChange={(e) => onTodoRegChange(e)}
                            >
                            <option value="">Address Type</option>
                            <option value="Home">Home</option>
                            <option value="Work">Work</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                </div>
                {contextValues.userAddressDetails.ua_address_type === 'Other' &&
                    <div className='col-lg-6'>
                        <div className="flotting-group mb-10">
                            <label >Other</label>
                            <input type="text" name="ua_address_type_other" className="form-control required"
                                value={contextValues.userAddressDetails.ua_address_type_other}
                                onChange={(e) => onTodoRegChange(e)}
                                placeholder="Other"
                            />
                        </div>
                    </div>
                }

            </div>
        </>
    )
}

export default AddressFrom