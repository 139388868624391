import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProductBox from "../../Components/Elements/Product_box"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { ApiService } from "../../Components/Services/apiservices";
import React, { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";


const SearchResult = () => {
    const { slug } = useParams()
    const [searchResultData, setsearchResultData] = useState([])
    const [isLoading, setisLoading] = useState(false)
    const [hasMore, sethasMore] = useState(false)
    const [PageNo, setPageNo] = useState(1);
    const [TotalProduct, setTotalProduct] = useState();
    const didMountRef = useRef(true)

    useEffect(() => {
        if (didMountRef.current) {
            if (slug.length >= 2) {
                getSearchData();
            }
        }
        didMountRef.current = false;
    })


    const getSearchData = () => {
        setisLoading(true)
        let pageNumber = PageNo;
        const dataString = {
            query: slug,
        }
        ApiService.postData(`getsearchdata?page=${pageNumber}`, dataString).then((res) => {
            if (res.status === "success") {
                if (pageNumber >= res.data.last_page) {
                    sethasMore(false);
                } else {
                    sethasMore(true);
                    setPageNo(pageNumber + 1);
                }
                if (res.data.data.length > 0) {
                    const mergeData = [...searchResultData, ...res.data.data];
                    setsearchResultData(mergeData);
                }
                setTotalProduct(res.data.total)
                setisLoading(false)
            } else {
                setisLoading(false)
            }
        });
    }


    return (<>
        <Header></Header>
        <div className="subheader">
        <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-8">
                         <h1>Search Result</h1> 
                       <p> {searchResultData?.length} Results found for the term '{slug}'</p> 
                      
                    </div>
                </div>
            </div>
            </div>
        <div className="listing-filter">
            <div className="listing-filter-top">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="filter-form">
                                <label>Colour</label>
                                <div className="filter-form-select">
                                    <select id="colour_group" name="colour_group">
                                        <option value="">All Colours</option>
                                        <option value="blue">Blue</option>
                                        <option value="brown">Brown</option>
                                        <option value="cream">Cream</option>
                                        <option value="green">Green</option>
                                        <option value="grey">Grey</option>
                                        <option value="mixed">Mixed</option>
                                        <option value="multi">Multi</option>
                                        <option value="natural">Natural</option>
                                        <option value="white">White</option>
                                    </select>
                                    <div className="select-value">
                                        All Colours <i class="ri-arrow-down-s-line ri-xl"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="filter-form">
                                <label>Colour</label>
                                <div className="filter-form-select">
                                    <select id="colour_group" name="colour_group">
                                        <option value="">All Colours</option>
                                        <option value="blue">Blue</option>
                                        <option value="brown">Brown</option>
                                        <option value="cream">Cream</option>
                                        <option value="green">Green</option>
                                        <option value="grey">Grey</option>
                                        <option value="mixed">Mixed</option>
                                        <option value="multi">Multi</option>
                                        <option value="natural">Natural</option>
                                        <option value="white">White</option>
                                    </select>
                                    <div className="select-value">
                                        All Colours <i class="ri-arrow-down-s-line ri-xl"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="filter-form">
                                <label>Colour</label>
                                <div className="filter-form-select">
                                    <select id="colour_group" name="colour_group">
                                        <option value="">All Colours</option>
                                        <option value="blue">Blue</option>
                                        <option value="brown">Brown</option>
                                        <option value="cream">Cream</option>
                                        <option value="green">Green</option>
                                        <option value="grey">Grey</option>
                                        <option value="mixed">Mixed</option>
                                        <option value="multi">Multi</option>
                                        <option value="natural">Natural</option>
                                        <option value="white">White</option>
                                    </select>
                                    <div className="select-value">
                                        All Colours <i class="ri-arrow-down-s-line ri-xl"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="filter-form">
                                <label>Colour</label>
                                <div className="filter-form-select">
                                    <select id="colour_group" name="colour_group">
                                        <option value="">All Colours</option>
                                        <option value="blue">Blue</option>
                                        <option value="brown">Brown</option>
                                        <option value="cream">Cream</option>
                                        <option value="green">Green</option>
                                        <option value="grey">Grey</option>
                                        <option value="mixed">Mixed</option>
                                        <option value="multi">Multi</option>
                                        <option value="natural">Natural</option>
                                        <option value="white">White</option>
                                    </select>
                                    <div className="select-value">
                                        All Colours <i class="ri-arrow-down-s-line ri-xl"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="listing-filter-bottom">
                <div className="container-fluid">
                    <div className="row align-items-center justify-contant-between">
                        <div className="col-lg-4">
                            <p className="mb-0">{searchResultData.length > 0 ? 1 : 0} {searchResultData?.length > 0 ? '-' : ''} {searchResultData?.length > 0 ? searchResultData?.length : ''} of {TotalProduct} results</p>
                        </div>
                        <div className="col-lg-4">
                            <ul className="vpagination">
                                <li>View</li>
                                <li><a href="#" className="active">{searchResultData?.length}</a></li>
                                <li><a href="#">{TotalProduct}</a></li>

                            </ul>
                        </div>
                        <div className="col-lg-4 justify-content-end d-flex">
                            <div className="sortby">
                                Sort:<select>Bestseller</select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <InfiniteScroll
            dataLength={searchResultData.length}
            next={getSearchData}
            hasMore={hasMore}
            style={{ overflow: "hidden !important" }}

        >
            {isLoading ? <>
                <section className="section-gap-medium">
                    <div className="container-fluid">
                        <div className="row">
            {  Array(8).fill().map((_, index) => (
          <div className="col-lg-3" key={index}>
            <div className="product text-center product-style-1">
              <div className="product-media-shap">
                <figure className="product-media">
                  <Skeleton height={450} width={'100%'} />
                </figure>
              </div>
              <div className="product-details">
                <h2 className="product-name">
                  <Skeleton width={'80%'} />
                </h2>
                <div className="product-price">
                  <Skeleton width={'150px'} />
                </div>
              </div>
            </div>
          </div>
        ))}
        </div>
        </div>
        </section>
            
            </> : <>
                <section className="section-gap-medium">
                    <div className="container-fluid">
                        <div className="row g-3">
                            {searchResultData.length > 0 ? <>
                                {searchResultData && searchResultData.map((item, index) => {
                                    return (<>
                                        <div className="col-lg-3 col-6">
                                            <ProductBox productValue={item} classType="product tx-center" key={index} home_product_style={2}></ProductBox>
                                        </div>

                                    </>)

                                })}
                            </> : <>
                                <h2 className="text-center">No Product Found</h2>
                            </>}

                        </div>
                    </div>
                </section>
            </>}


        </InfiniteScroll>

        <Footer></Footer>

    </>)
}

export default SearchResult