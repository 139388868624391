import Menu from "./menu"
import React, { useContext, useEffect, useRef, useState } from "react";
import { ApiService } from '../Services/apiservices'
import constant from "../Services/constant";
import DataContext from "../Elements/context";
import CartModal from "../Modals/cart_modal";
import { useLocation, useNavigate } from "react-router-dom";
import { BrowserView, isBrowser, isMobile, MobileView } from "react-device-detect";
import MobileMenu from "../Modals/sidebarmenu";
import { toast } from "react-toastify";
import SearchModal from "../Modals/searchmodal";
import SignUpModal from "../Modals/newsletter_modal";

const Header = () => {
  const contextValues = useContext(DataContext)
  const [menuData, setmenuData] = useState([])
  const [isLoading, setisLoading] = useState(true)
  const [imageUrl, setImageUrl] = useState("");
  const navigate = useNavigate()
  const [searchQuery, setsearchQuery] = useState('')


  const didMountRef = useRef(true)
  const session_token = localStorage.getItem('USER_TOKEN')
  const session_Trade_token = localStorage.getItem('USER_TRADE_TOKEN')
  useEffect(() => {
    if (didMountRef.current) {
      getmenuList()
      PopupSubscribeModal()
    }
    didMountRef.current = false;
    if (isBrowser) {
      const handleScroll = () => {
        if (window.scrollY > 200) {
          document.getElementById("header_middle").classList.add("fixed-head");
        } else {
          document.getElementById("header_middle").classList.remove("fixed-head");
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);



  const PopupSubscribeModal=()=>{
    if ( localStorage.getItem("MODALOPEN") !== "FALSE") {
          
      contextValues.setToggleSignRukmanModal(true)
  } else {
    contextValues.setToggleSignRukmanModal(false)
  }
  }
  const MobileMenuModal = () => {
    contextValues.setToggleMenuModal(!contextValues.toggleMenuModal)
  }
  const searchModal = () => {
    contextValues.setToggleSearchModal(!contextValues.toggleSearchModal)
  }

  const getmenuList = () => {
    ApiService.fetchData('/menue-list').then(result => {
      if (result.status == 'success') {
        setmenuData(result.data)
        setImageUrl(result.imageUrl);
        setTimeout(() => {
          setisLoading(false)
        }, 500)
      }
      else {
        setTimeout(() => {
          setisLoading(false)
        }, 500)
      }

    }).catch((error) => {
      setTimeout(() => {
        setisLoading(false)
      }, 500)
    })
  }

  const cartModal = () => {
    contextValues.setToggleCartModal(!contextValues.toggleCartModal)
  }

  const location = useLocation()

  const onSearchBttnClick = () => {
    if (searchQuery) {
      if (searchQuery.length >= 2) {
        contextValues.setToggleSearchModal(false)

        let recentSearch = JSON.parse(localStorage.getItem('recentSearch')) || [];
        if (!recentSearch.includes(searchQuery)) {
          recentSearch.push(searchQuery);
          localStorage.setItem('recentSearch', JSON.stringify(recentSearch));
        }
        window.location.href = `/search-result/${searchQuery}`
      }
      else {
        toast.error('Please type atleast two character for better search')
      }
    } else {
      toast.error('Please type something here to search')
    }
  }
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearchBttnClick();
    }
  };



  return (<>
    <BrowserView>
      <header className={`header${location.pathname == '/' ? '' : ' innerHeader'}`}>
        <div className="header-middle" id="header_middle">
          <div className="container">
            <div className="header-left">
              <div className="header-search">
                <input placeholder="Search" onChange={(e) => { setsearchQuery(e.target.value); }} value={searchQuery} onKeyDown={handleKeyDown} ></input>
                <button type="button" className="searchbutton"><i class="ri-search-line"></i></button>
              </div>

            </div>
            <div className="header-center">
              <a href="/" className="logo"><img src="/img/logo.png"></img></a>
              <a href="/" className="logo-dark"><img src="/img/logo-dark.png"></img></a>
            </div>
            <div className="header-right justify-content-end">
              <ul className="rightHeaderlist">
                <li>
                  {(session_token && session_token !== null )||(session_Trade_token && session_Trade_token !== null ) ? <>
                    <a href="/account"><i class="ri-user-line ri-xl"></i></a>
                  </> : <>
                    <a href="/login"><i class="ri-user-line ri-xl"></i></a>
                  </>}

                </li>
                <li>
                {(session_token && session_token !== null )||(session_Trade_token && session_Trade_token !== null ) ? <>
                    <a href="/wishlist"><i class="ri-heart-line ri-xl"></i></a>
                  </> : <>
                    <a href="/login"><i class="ri-heart-line ri-xl"></i></a>
                  </>}
                </li>
                <li >
                  <a href="/cart"> {contextValues.cartCount && contextValues.cartCount > 0 ?
                  <span className="count">{contextValues.cartCount}</span> : ''
                  }<i class="ri-shopping-bag-3-line ri-xl"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="header-bottom">
          <Menu menuData={menuData} isLoading={isLoading} imageUrl={imageUrl}></Menu>
        </div>
      </header>

    </BrowserView>
    <MobileView>

      <header className={`header${location.pathname == '/' ? '' : ' innerHeader'}`}>
        <div className="header-middle" id="header_middle">
          <div className="container">
            <div className="header-left">
              <ul className="leftHeaderlist">
                <li onClick={(e) => { e.preventDefault(); MobileMenuModal() }}>
                  <a href="javascript:void(0)"><i class="ri-menu-2-fill ri-xl"></i></a>
                </li>
                <li onClick={() => { searchModal() }}>
                  <a href="javascript:void(0)"><i class="ri-search-line ri-xl"></i></a>
                </li>
              </ul>
            </div>
            <div className="header-center">
              <a href="/" className="logo"><img src="/img/logo.png"></img></a>
              <a href="/" className="logo-dark"><img src="/img/logo-dark.png"></img></a>
            </div>
            <div className="header-right justify-content-end">
              <ul className="rightHeaderlist">
                <li>
                  {session_token && session_token !== null ? <>
                    <a href="/account"><i class="ri-user-line ri-xl"></i></a>
                  </> : <>
                    <a href="/login"><i class="ri-user-line ri-xl"></i></a>
                  </>}

                </li>
                <li >
                  <a href="/cart"> {contextValues.cartCount && contextValues.cartCount > 0 ?
                  <span className="count">{contextValues.cartCount}</span> : ''
                  }<i class="ri-shopping-bag-3-line ri-xl"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </header>
    </MobileView>


    {contextValues.toggleCartModal && <CartModal></CartModal>}
    {contextValues.toggleSearchModal && <SearchModal onSearchBttnClick={onSearchBttnClick} handleKeyDown={handleKeyDown} searchQuery={searchQuery} setsearchQuery={setsearchQuery}></SearchModal>}
    {contextValues.toggleMenuModal && <MobileMenu></MobileMenu>}
    {contextValues.toggleSignRukmanModal && <SignUpModal></SignUpModal> }
  </>)
}

export default Header