import Footer from "../../Components/Footer"
import Header from "../../Components/Header"

const TermsCondition=()=>{
    return(<>
    <Header></Header>
    
    <p>TermsCondition</p>
    <Footer></Footer>
    </>)
}

export default TermsCondition