import Accordion from 'react-bootstrap/Accordion';


const ShippingTabs = () => {
    return (<>
   
        <section className="section-gap-medium border-top">
            <div className="container">
                <div className='row'>
                    <div className='col-12'>
                        <div className='pshippingbox'>
                            <h5>Shipping</h5>
                            <p className='mb-1'>This item ships via freight. The final delivery options are determined in checkout. <a href="/shipping" target="_blank" style={{textDecoration:'underline' , textDecorationColor:'black'}}>See delivery methods + rates →</a></p>
                        </div>
                        <div className='pshippingbox'>
                            <h5>Returns</h5>
                            <p className='mb-1'>If you aren’t happy with your purchase, notify us within 30 days of receipt and we will take it back for a refund of the returning item(s) minus a 10% return fee.</p>
                            <p className='mb-1'><a href="/exchange-return" target="_blank" style={{textDecoration:'underline' , textDecorationColor:'black'}}>See full return policy + details →</a></p>
                        </div>
                        <div className='pshippingbox'>
                            <h6>Warranty</h6>
                            <p className='mb-1'>We stand behind every design we make. Should your product have any defects, we offer a 5-year warranty.</p>
                            <p className='mb-1'><a href="/warranty" target="_blank" style={{textDecoration:'underline' , textDecorationColor:'black'}}>See full warranty details →</a></p>
                        </div>
                        <div className='pshippingbox' style={{borderBottom:'0px', marginBottom:'0'}}>
                            <h6>Sustainability</h6>
                            <p className='mb-1'>From using durable materials to reducing foam in packaging, we’re continually finding ways to minimize our environmental impact while creating high-quality designs we all love for the long haul.</p>
                            <p className='mb-1'><a href="/sustainability" target="_blank" style={{textDecoration:'underline' , textDecorationColor:'black'}}>See our full sustainability plan →</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}

export default ShippingTabs