import { BrowserView, MobileView } from "react-device-detect"
import React, { useContext, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import constant from "../../Components/Services/constant";
import { ApiService } from "../../Components/Services/apiservices";

const Blog = () => {
    const didMountRef = useRef(true)
    const navigate=useNavigate()
    const [blogsData, setblogData] = useState([])
    const [blogimgUrl, setblogimgUrl] = useState('')
    const [isLoading, setisLoading] = useState(true)
    useEffect(() => {
        if (didMountRef.current) {
            getblogsData()

        }
        didMountRef.current = false;
    }, []);
    const getblogsData = () => {
        setisLoading(true)
        ApiService.fetchData('/blog-list').then((res) => {
            if (res.status == 'success') {
                setblogimgUrl(res.blog_image_path)
                setblogData(res.blogsData)
                setisLoading(false)
            }
            else {
                setisLoading(false)
            }
        }).catch(() => {
            setisLoading(false)
        })
    }
    return (<>


        <Header innerHeader={'innerHeader'}></Header>
        {isLoading ?<>
            <section className="section-gap-medium tx-center">
            <div className="section-title tx-center">
                <p><Skeleton width={'200px'}></Skeleton></p>
                <h1><Skeleton width={'200px'}></Skeleton></h1>
            </div>
        </section>
        <section className="section-gap-medium">
            <div className="container-fluid">
                <div className="row">
                    {[...Array(6)].map((_ , index)=>{
                        return(<>
                         <div className="col-lg-4">
                        <div >
                            <div className="">
                                <div >
                                    <Skeleton width={'500px'} height={'500px'}></Skeleton>
                                </div>
                            </div>
                            <div >
                                <h2 > <Skeleton width={'300px'} ></Skeleton></h2>
                                <p > <Skeleton width={'400px'}></Skeleton></p></div>
                            <a href="#" >
                            </a>
                        </div>
                    </div>
                        
                        
                        </>)
                    })}
                   
                </div>
            </div>
        </section>
        </>:<>
        {blogsData && blogsData.length>0 ?<> 
        <section className="section-gap-medium tx-center">
            <div className="section-title tx-center">
                <h1>BLOG</h1>
            </div>
        </section>
        <section className="section-gap-medium">
            <div className="container-fluid">
                <div className="row">
                    {blogsData.map((item , index)=>{
                        return(<>
                         <div className="col-lg-4" key={index}>
                        <div  onClick={()=>{navigate(`/blog/${item.blog_slug}`)}}>
                            
                            <div >
                                <img src={`${blogimgUrl + item.blog_image}`}></img>
                            </div>
                          
                            <div >
                                <h2 >{item.blog_name}</h2>
                                <p >{item.blog_short_description}</p></div>
                            <a href={`/blog/${item.blog_slug}`} >
                            </a>
                        </div>
                    </div>
                        
                        
                        </>)
                    })}
                   
                </div>
            </div>
        </section></>:null}
        </>}
       
        <Footer></Footer>

    </>)
}

export default Blog