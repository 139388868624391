
import React, { useContext, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../Services/apiservices";
import DataContext from "../Elements/context";
import constant from "../Services/constant";
import { useNavigate } from "react-router-dom";
import { validEmail } from "../Elements/Regex";





const Footer = () => {
    const contextValues = useContext(DataContext)
    const navigate = useNavigate()
    const didMountRef = useRef(true)
    const [footerData, setFooterData] = useState({})
    const [isloading, setisloading] = useState(false)
    const [footerData1, setFooterData1] = useState({})
    const [footerData2, setFooterData2] = useState({})
    const [footerData3, setFooterData3] = useState({})
    const [footerData4, setFooterData4] = useState({})
    const [footerData5, setFooterData5] = useState({})
    const [footerData6, setFooterData6] = useState({})
    useEffect(() => {
        if (didMountRef.current) {
            setisloading(true)
            ApiService.fetchData('footer').then(result => {
                if (result.status == 'success') {

                    setFooterData(result.footerIdData)
                    setFooterData1(result.footerIdData?.footer_desc1)
                    setFooterData2(result.footerIdData?.footer_desc2)
                    setFooterData3(result.footerIdData?.footer_desc3)
                    setFooterData4(result.footerIdData?.footer_desc4)
                    setFooterData5(result.footerIdData?.footer_desc5)
                    setFooterData6(result.footerIdData?.footer_desc6)
                    setTimeout(() => {
                        setisloading(false)
                    }, [500])

                }
                else {
                    setTimeout(() => {
                        setisloading(false)
                    }, [500])
                }

            }).catch(() => {
                setTimeout(() => {
                    setisloading(false)
                }, [500])
            })

        }
        didMountRef.current = false;
    }, []);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [newsletterDetail, setNewsletterDetail] = useState({
        newsletter_email: ""
    })
    const newsletterProcess = () => {
      
        let counter = 0;
        const myElements = document.getElementsByClassName("newsletterRequired");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === '') {
                myElements[i].style.border = '1px solid red';
                counter++;
               
            } else {
                myElements[i].style.border = '';
            }
        }
        if (counter === 0) {
            setErrorMessage("");
            if (!validEmail.test(newsletterDetail.newsletter_email)) {
                setErrorMessage("Please enter valid Email Id");
                setTimeout(() => {
                    setErrorMessage('')
                    // resetNewsletterForm()
                   
                }, [2000])

                return false;
            }
            ApiService.postData("newsletter", newsletterDetail).then((res) => {
              
                if (res.status === "success") {
                    setSuccessMessage(res.message);
                    setErrorMessage("");
                    setTimeout(() => {
                        setSuccessMessage("");
                        setNewsletterDetail({ newsletter_email: "" });
                    }, 2000);
                } else {
                    setErrorMessage(res.message);
                    setSuccessMessage("");
                    setTimeout(() => {
                        setNewsletterDetail({ newsletter_email: "" });
                        setErrorMessage("");
                    }, 2000)
                }
            }).catch(() => {
               
                setErrorMessage("An error occurred. Please try again.");
            });
        }
    };

    const onTodOChange = (e) => {
        const { name, value } = e.target;
        setNewsletterDetail((prevState) => ({
            ...prevState,
            [name]: value,
        }))

        if (value !== '') {
            e.target.style.border = '';
        }
    };


    return (<>
        <BrowserView>
            <footer className="footer">
                <div className="footer-top">
                    <div className="container">

                        <div className="row">
                            {/* Logo section */}
                            <div className="col-lg-3">
                                {isloading ? (
                                    <Skeleton width={'100px'} height={'100px'} circle={true} />
                                ) : (
                                    <a href="/" className="footer-logo">
                                        <img
                                            src={contextValues.settingsData.logo
                                                ? contextValues.settingsImageBaseUrl + contextValues.settingsData.logo
                                                : '/img/logo_default.png'}
                                            alt="Logo"
                                        />
                                    </a>
                                )}
                            </div>

                            {/* Showroom Address section */}
                            {isloading ? (
                                <div className="col-lg-4">
                                    <Skeleton height={'100px'} />
                                </div>
                            ) : contextValues.settingsData.address ? (
                                <div className="col-lg-4">
                                    <div className="icbbox">
                                        <div className="icbbox-icon">
                                            <i className="ri-map-pin-line ri-xl"></i>
                                        </div>
                                        <div className="icbbox-content">
                                            <h5>Showroom Address</h5>
                                            <p>{contextValues.settingsData.address}</p>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {/* Appointment section */}
                            <div className="col-lg-2" onClick={() => navigate('/book-an-appointment')}>
                                {isloading ? (
                                    <Skeleton height={'100px'} />
                                ) : (
                                    <div className="icbbox">
                                        <div className="icbbox-icon">
                                            <i className="ri-calendar-line ri-xl"></i>
                                        </div>
                                        <div className="icbbox-content">
                                            <h5>Mon - Fri</h5>
                                            <p>9AM-6PM</p>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Call Us section */}
                            <div className="col-lg-3">
                                {isloading ? (
                                    <Skeleton height={'100px'} />
                                ) : (
                                    <div className="icbbox">
                                        <div className="icbbox-icon">
                                            <i className="ri-phone-line ri-xl"></i>
                                        </div>
                                        <div className="icbbox-content">
                                            {contextValues.settingsData && (
                                                <>
                                                    <h5>Call Us</h5>
                                                    <a href={`tel:${contextValues.settingsData.admin_support_mobile}`} style={{ color: 'black' }}>
                                                        <p>{contextValues.settingsData.admin_support_mobile}</p>
                                                    </a>
                                                </>
                                            )}
                                            <ul className="footer-social">
                                                {contextValues.settingsData.facebook_url && (
                                                    <li>
                                                        <a href={contextValues.settingsData.facebook_url} target="_blank">
                                                            <i className="ri-facebook-fill"></i>
                                                        </a>
                                                    </li>
                                                )}
                                                {contextValues.settingsData.twitter_url && (
                                                    <li>
                                                        <a href={contextValues.settingsData.twitter_url} target="_blank">
                                                            <i className="ri-twitter-x-line"></i>
                                                        </a>
                                                    </li>
                                                )}
                                                {contextValues.settingsData.instagram_url && (
                                                    <li>
                                                        <a href={contextValues.settingsData.instagram_url} target="_blank">
                                                            <i className="ri-instagram-line"></i>
                                                        </a>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-middle">
                    <div className="container">
                        <div className="row">
                            {isloading ? <> {[...Array(4)].map((item, index) => (
                                <div key={index} className="col-lg-3">
                                    <h2 className="footerTitle"><Skeleton width={150} /></h2>
                                    <div >
                                        <ul className="footerList">
                                            <li><a href="javascript:void(0)"><Skeleton width={100} /></a></li>
                                            <li><a href="javascript:void(0)"><Skeleton width={100} /></a></li>
                                            <li><a href="javascript:void(0)"><Skeleton width={100} /></a></li>

                                        </ul>
                                    </div>
                                </div>
                            ))}</>
                                :

                                <>
                                    {footerData1 && (<div className="col"> <h2 className="footer-title">{footerData.footer_title1}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: footerData1 }}></div></div>)}
                                    {footerData2 && (<div className="col"> <h2 className="footer-title">{footerData.footer_title2}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: footerData2 }}></div></div>)}
                                    {footerData3 && (<div className="col"> <h2 className="footer-title">{footerData.footer_title3}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: footerData3 }}></div></div>)}
                                    {footerData4 && (<div className="col"> <h2 className="footer-title">{footerData.footer_title4}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: footerData4 }}></div></div>)}
                                    {footerData5 && (<div className="col-lg-3"> <h2 className="footer-title">{footerData.footer_title5}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: footerData5 }}></div>
                                        <h2 class="footer-title mt-30">Subscribe to recive news from rukman</h2>
                                        {successMessage && <div class="alert alert-success" role="alert">{successMessage}</div>}
                                        {errorMessage && <div class="alert alert-danger" role="alert">{errorMessage}</div>}
                                        <div className="newsletter">
                                           
                                            <input placeholder="Enter your email address" className="newsletterRequired"
                                                name='newsletter_email'
                                                value={newsletterDetail.newsletter_email}
                                                onChange={(e) => onTodOChange(e)}></input>
                                            <button className="subscribebutton" type="button" onClick={() => { newsletterProcess() }}>Subscribe</button>
                                        </div>
                                    </div>)}


                                </>}

                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12"></div>
                        </div>
                    </div>
                    <div className="copyright">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <p className="mb-0 tx-14">Copyright @ 2024 | All Right Reserved Rukman Creations</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </BrowserView>
        <MobileView>
            <footer className="footer">
                <div className="footer-top text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <a href="/" className="footer-logo">
                                    <img src={contextValues.settingsData.logo ? contextValues.settingsImageBaseUrl + contextValues.settingsData.logo : constant.DEFAULT_IMAGE}></img>
                                </a>
                            </div>
                            {contextValues.settingsData.address ? <>
                                <div className="col-lg-12">
                                    <div className="icbbox">
                                        <div className="icbbox-icon"><i class="ri-map-pin-line ri-xl"></i></div>
                                        <div className="icbbox-content">
                                            <h5>Showroom Address</h5>
                                            <p>{contextValues.settingsData.address}</p>
                                        </div>
                                    </div>
                                </div>
                            </> : ''}

                            <div className="col-6" onClick={() => { navigate('/book-an-appointment') }}>
                                <div className="icbbox">
                                    <div className="icbbox-icon"><i class="ri-calendar-line ri-xl"></i></div>
                                    <div className="icbbox-content">
                                        <h5>Mon - Fri</h5>
                                        <p>9AM-6PM</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="icbbox">
                                    <div className="icbbox-icon"><i class="ri-phone-line ri-xl"></i></div>
                                    <div className="icbbox-content">
                                        {contextValues.settingsData && (<>
                                            <h5>Call Us</h5>
                                            <a href={`tel:${contextValues.settingsData.admin_support_mobile}`} style={{ color: 'black' }}>
                                                <p>{contextValues.settingsData.admin_support_mobile}</p>
                                            </a>

                                        </>

                                        )}

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <ul className="footer-social">
                                    {contextValues.settingsData.facebook_url && (
                                        <li><a href={contextValues.settingsData.facebook_url} target='_blank'><i className="ri-facebook-fill"></i></a></li>)}
                                    {contextValues.settingsData.twitter_url && (
                                        <li><a href={contextValues.settingsData.twitter_url} target='_blank'><i className="ri-twitter-x-line"></i></a></li>)}
                                    {contextValues?.settingsData?.instagram_url && (
                                        <li><a href={contextValues?.settingsData?.instagram_url} target='_blank'><i className="ri-instagram-line"></i></a></li>)}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-middle">
                    <Accordion>
                        {footerData1 && (<Accordion.Item eventKey="0">
                            <Accordion.Header>{footerData.footer_title1}</Accordion.Header>
                            <Accordion.Body dangerouslySetInnerHTML={{ __html: footerData1 }}>
                            </Accordion.Body>
                        </Accordion.Item>)}
                        {footerData2 && (<Accordion.Item eventKey="1">

                            <Accordion.Header>{footerData.footer_title2}</Accordion.Header>
                            <Accordion.Body dangerouslySetInnerHTML={{ __html: footerData2 }}>
                            </Accordion.Body>
                        </Accordion.Item>)}
                        {footerData3 && (
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>{footerData.footer_title3}</Accordion.Header>
                                <Accordion.Body dangerouslySetInnerHTML={{ __html: footerData3 }}>
                                </Accordion.Body>
                            </Accordion.Item>
                        )}
                        {footerData4 && (
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>{footerData.footer_title4}</Accordion.Header>
                                <Accordion.Body dangerouslySetInnerHTML={{ __html: footerData4 }}>
                                </Accordion.Body>
                            </Accordion.Item>
                        )}
                        {footerData5 && (<Accordion.Item eventKey="4">
                            <Accordion.Header>{footerData.footer_title5}</Accordion.Header>
                            <Accordion.Body dangerouslySetInnerHTML={{ __html: footerData5 }}>
                            </Accordion.Body>
                        </Accordion.Item>)}

                    </Accordion>
                </div>
                <div className="footer-bottom">
                    <div className="copyright">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <p className="mb-0 tx-14">Copyright @ 2024 | All Right Reserved Rukman Creations</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </MobileView>

    </>)
}

export default Footer