import { validEmail, validNumber } from "../../Components/Elements/Regex";
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { useState, useRef, useCallback, useEffect } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import Alert from 'react-bootstrap/Alert';


const TradeLogin = () => {
  const [Step, setStep] = useState(1)
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [tradeProgram, setTradeProgram] = useState({
    user_fname: '',
    user_lname: '',
    user_company: "",
    user_country: "1",
    other_country: '',
    buisness_address: '',
    appartment_name: '',
    postal_code: '',
    email: "",
    buisness_phone: '',
    town: "",
    title: "",
    adharnumber: '',
    weblink: "",
    buisness_card: '',
    buisness_liecence: '',
    gst_number: '',
    product_collection: '',
    product_category: [],
    moq: '',
    comment_box: "",
    trade_otp: '',
    user_password: '',
    user_id: 0
  });
  const [allCategory, setallCategory] = useState([])
  const didMountRef = useRef(true);

  useEffect(() => {
    if (didMountRef.current) {
      const userData = JSON.parse(localStorage.getItem('TRADE_USER_DATA'));
      if (userData) {
        setTradeProgram((prevState) => ({
          ...prevState,
          ['user_id']: userData.user_id,
        }));
        setStep(userData.user_trade_step + 1)
      }
      getAllCategories()
    }
    didMountRef.current = false;
  }, []);
  const onTodoRegChange = (e) => {
    setErrorMessage('')
    const { name, value } = e.target;
    setTradeProgram((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value !== '') {
      e.target.style.border = '';
    }
  }
  const getAllCategories = () => {
    ApiService.fetchData('all-categories').then((res) => {
      if (res.status == 'success') {
        setallCategory(res?.resCategory)
      }
    }).catch(() => {

    })
  }
  const validateStep1 = () => {
    if (!tradeProgram.user_country) {
      return 'Please choose the country ';
    }
    if (tradeProgram.user_country == '0') {
      if (!tradeProgram.other_country) {
        return 'Please enter the other country  ';
      }
    }
    if (!validNumber.test(tradeProgram.buisness_phone)) {
      return 'Please enter the Valid Number ';
    }
    if (!validEmail.test(tradeProgram.email)) {
      return 'Please enter the Valid Email ';
    }
    return null;
  }
  const validateStep2 = () => {
    if (tradeProgram.trade_otp.length !== 4) {
      return 'Please enter the correct otp  ';
    }

    return null;
  }
  const validateStep3 = () => {
    if (tradeProgram.adharnumber.length !== 4) {
      return 'Please enter last four digit of aadhar number ';
    }
    if (tradeProgram.user_country == 'Other') {
      if (!tradeProgram.other_country) {
        return 'Please enter the other country  ';
      }
    }
    if (!tradeProgram.weblink && !tradeProgram.buisness_card) {
      return 'Please enter the Weblink or upload buisness card   ';
    }
    if (!tradeProgram.buisness_liecence && !tradeProgram.gst_number) {
      return 'Please upload the buisness liecence or enter the gst number   ';
    }
    return null;
  }
  const validateStep4 = () => {
    if (!tradeProgram.product_collection) {
      return 'Please select the product collection ';
    }
    if (!tradeProgram.product_category) {
      return 'Please select the product category ';
    }

  }
  const handleSingleImageUpload = (event, type) => {
    setErrorMessage('');
    const file = event.target.files[0];
    if (!file) {
      setErrorMessage('No file selected.');
      return;
    }
    if (["image/jpeg", "image/png", "image/jpg", "image/webp"].includes(file.type)) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1];

        if (type === 'licence') {
          setTradeProgram((prevState) => ({
            ...prevState,
            buisness_liecence: base64String,
          }));
        } else {
          setTradeProgram((prevState) => ({
            ...prevState,
            buisness_card: base64String,
          }));
        }
      };

      reader.readAsDataURL(file);
    } else {
      setErrorMessage('Please select an image file (jpg, jpeg, png, webp).');
    }
  };
  const onSubmitForm = (step) => {
    let counter = 0;
    if (step == 1) {
      const myElements = document.getElementsByClassName("requiredstep1");
      for (let i = 0; i < myElements.length; i++) {
        if (myElements[i].value === '') {
          myElements[i].style.border = '1px solid red';
          counter++;
        } else {
          myElements[i].style.border = '';
        }
      }
    }
    else if (step == 2) {
      const myElements = document.getElementsByClassName("requiredstep2");
      for (let i = 0; i < myElements.length; i++) {
        if (myElements[i].value === '') {
          myElements[i].style.border = '1px solid red';
          counter++;
        } else {
          myElements[i].style.border = '';
        }
      }
    }
    else if (step == 3) {
      const myElements = document.getElementsByClassName("requiredstep3");
      for (let i = 0; i < myElements.length; i++) {
        if (myElements[i].value === '') {
          myElements[i].style.border = '1px solid red';
          counter++;
        } else {
          myElements[i].style.border = '';
        }
      }
    }
    else if (step == 4) {
      const myElements = document.getElementsByClassName("requiredstep4");
      for (let i = 0; i < myElements.length; i++) {
        if (myElements[i].value === '') {
          myElements[i].style.border = '1px solid red';
          counter++;
        } else {
          myElements[i].style.border = '';
        }
      }
    }
    if (counter == 0) {
      setErrorMessage('')
      let errorMessage = null;
      switch (Step) {
        case 1:
          errorMessage = validateStep1();
          break;
        case 2:
          errorMessage = validateStep2();
          break;
        case 3:
          errorMessage = validateStep3();
          break;
        case 4:
          errorMessage = validateStep4();
          break;
        default:
          break;
      }
      if (errorMessage) {
        setErrorMessage(errorMessage);
        return;
      }
      const dataString = {
        user_type: 2,
        user_email: tradeProgram.email,
        user_mobile: tradeProgram.buisness_phone,
        user_fname: tradeProgram.user_fname,
        user_lname: tradeProgram.user_lname,
        user_company_name: tradeProgram.user_company,
        user_trade_title: tradeProgram.title,
        user_trade_country_type: tradeProgram.user_country,
        user_trade_country_name: tradeProgram.other_country,
        user_trade_optional_address: tradeProgram.appartment_name,
        user_trade_business_address: tradeProgram.buisness_address,
        user_city: tradeProgram.town,
        user_pincode: tradeProgram.postal_code,
        user_password: tradeProgram.user_password,
        user_trade_step: step,
        user_mobile_otp: tradeProgram.trade_otp,
        user_id: tradeProgram.user_id,
        user_trade_aadhar_no: tradeProgram.adharnumber,
        user_trade_company_link: tradeProgram.weblink,
        user_trade_business_license: tradeProgram.buisness_liecence,
        user_trade_business_card: tradeProgram.buisness_card,
        user_trade_gst_no: tradeProgram.gst_number,
        user_product_collection: tradeProgram.product_collection,
        user_product_category: tradeProgram.product_category,
        user_product_moq: tradeProgram.moq,
        user_comment_box: tradeProgram.comment_box
      }
      setButtonLoader(true)

      ApiService.postData('traderegisterprocess', dataString).then((res) => {
        if (res.status == 'success') {
          if (step < 4) {
            setStep(step + 1)
          }
          setButtonLoader(false)
          const Userdata = res.loginData
          localStorage.setItem('TRADE_USER_DATA', JSON.stringify(Userdata));
          setTradeProgram((prevState) => ({
            ...prevState,
            ['user_id']: Userdata.user_id,
          }));
          if (step == 4) {
            localStorage.setItem("USER_TRADE_TOKEN", res.loginData.user_token)
            localStorage.removeItem('TRADE_USER_DATA')
            localStorage.removeItem('USER_TOKEN')
            setStep(5)
          }

        }
        else if (res.status == 'error') {
          setButtonLoader(false)
          setErrorMessage(res.message)
        }
      }).catch(() => {
        setButtonLoader(false)
      })
    }
  }
  const onTodoCateChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions).map((option) => option.value);
    setTradeProgram((prevState) => ({
      ...prevState,
      product_category: selectedOptions,
    }));
  };


  return (<>
    <Header></Header>
    <section className="section-gap-small bg-light text-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <h1>Trade Program</h1>
          </div>
        </div>
      </div>
    </section>
    <section className="section-gap-small bg-gray">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            {errorMessage ? <Alert key={'danger'} className='danger' variant='danger'>{errorMessage}</Alert> : ''}
            {successMessage ? <Alert key={'success'} className='success' variant='success'>{successMessage}</Alert> : ''}
            {Step === 1 && (
              <div className="tradebox">
                <div className="tradebox-inner">
                  <p><strong>Join our Trade Program today.</strong> Members receive an exclusive 20% discount on all full-price merchandise at Crate & Barrel, CB2 and Crate & Kids, plus even more perks. Complete this short application and a Trade Program Specialist will reach out to you via phone or email within a few business days.</p>
                  <div className="row g-3 mt-4">
                    <div className="col-lg-6">
                      <div className="form-group mb-10">
                        <label>First Name <span className="required">*</span></label>
                        <input type="text" placeholder="First Name" name="user_fname" className="requiredstep1" onChange={(e) => { onTodoRegChange(e) }} />

                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-10">
                        <label>Last Name <span className="required">*</span></label>
                        <input type="text" placeholder="Last Name" name="user_lname" className="requiredstep1" onChange={(e) => { onTodoRegChange(e) }} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-10">
                        <label>Name Of Company <span className="required">*</span></label>
                        <input type="text" placeholder="Name Of Company" name='user_company' className="requiredstep1" onChange={(e) => { onTodoRegChange(e) }} />

                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-10">
                        <label>Title<span className="required">*</span></label>
                        <input type="text" placeholder="Title" name="title" className="requiredstep1" onChange={(e) => { onTodoRegChange(e) }} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-10">
                        <label>Select Your Country<span className="required">*</span></label>
                        <div>
                          <label>
                            <input
                              type="radio"
                              name="user_country"
                              value="1"
                              checked={tradeProgram.user_country === '1'}
                              onChange={(e) => onTodoRegChange(e)}
                            />
                            India
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="radio"
                              name="user_country"
                              checked={tradeProgram.user_country === '0'}
                              value="0"
                              onChange={(e) => onTodoRegChange(e)}
                            />
                            Other Country
                          </label>
                        </div>
                      </div>
                    </div>
                    {tradeProgram.user_country === '0' && <div className="col-lg-12">
                      <div className="form-group mb-10">
                        <label>Other<span className="required">*</span></label>
                        <input type="text" placeholder="Other Country" name="other_country" onChange={(e) => { onTodoRegChange(e) }} />
                      </div>
                    </div>}

                    <div className="col-lg-6">
                      <div className="form-group mb-10">
                        <label>Buisness Address<span className="required">*</span></label>
                        <input type="text" placeholder="Buisness Address" name="buisness_address" className="requiredstep1" onChange={(e) => { onTodoRegChange(e) }} />
                      </div>
                    </div>

                    {/* <div className="col-lg-6">
                      <div className="form-group mb-10">
                        <label>Buisness Address<span className="required">*</span></label>
                        <input type="text" placeholder="Title" name="title" onChange={() => { onTodoRegChange() }} />
                      </div>
                    </div> */}
                    <div className="col-lg-6">
                      <div className="form-group mb-10">
                        <label>Appartment, suite , Building , Other<span className="required">*</span></label>
                        <input type="text" placeholder="Appartment,suite , Building , Other" className="requiredstep1" name="appartment_name" onChange={(e) => { onTodoRegChange(e) }} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-10">
                        <label>Postal Code <span className="required">*</span></label>
                        <input type="number" placeholder="postal code" name="postal_code" className="requiredstep1" onChange={(e) => { onTodoRegChange(e) }} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-10">
                        <label>Town/City<span className="required">*</span></label>
                        <input type="text" placeholder="Town/City" name="town" className="requiredstep1" onChange={(e) => { onTodoRegChange(e) }} />
                      </div>
                    </div>
                    {/* <div className="col-lg-6">
                      <div className="form-group mb-10">
                        <label>Select<span className="required">*</span></label>
                        <select name="province" onChange={(e) => onTodoRegChange(e)} className="form-control requiredstep1" >
                          <option value="">Select Province</option>
                          <option value="Province 1">Province 1</option>
                          <option value="Province 2">Province 2</option>
                          <option value="Province 3">Province 3</option>
                          <option value="Province 4">Province 4</option>
                          <option value="Province 5">Province 5</option>
                          <option value="Province 6">Province 6</option>
                          <option value="Province 7">Province 7</option>
                          <option value="Province 8">Province 8</option>
                        </select>
                      </div>
                    </div> */}
                    <div className="col-lg-6">
                      <div className="form-group mb-10">
                        <label>Buisness Phone<span className="required">*</span></label>
                        <input type="number" placeholder="Buisness Phone " className="requiredstep1" name="buisness_phone" onChange={(e) => { onTodoRegChange(e) }} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-10">
                        <label>Email<span className="required">*</span></label>
                        <input type="email" placeholder="Email" name="email" className="requiredstep1" onChange={(e) => { onTodoRegChange(e) }} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-10">
                        <label>Password<span className="required">*</span></label>
                        <input type="password" placeholder="Password" name="password" className="requiredstep1" onChange={(e) => { onTodoRegChange(e) }} />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5 align-items-center">
                    <div className="col-lg-10">
                      <p className="mb-0 tx-12">To learn more about how we use your information, read our Privacy Policy.</p>
                    </div>
                    <div className="col-lg-2">
                      <button type="button" className="btn btn-full btn-md btn-primary" onClick={() => { onSubmitForm(1) }}> {buttonLoader ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : ("Next")}</button>
                    </div>
                  </div>
                </div>
              </div>

            )}
            {Step === 2 && (
              <div className="tradebox">
                <div className="tradebox-inner">
                  <p className="text-center"><strong>Verify OTP</strong> </p>
                  <div className="row g-3 mt-4">
                    <div className="col-lg-12">
                      <div className="form-group mb-10">
                        <label>OTP <span className="required" >*</span></label>
                        <input type="number" placeholder="Enter Your otp" name="trade_otp" className="requiredstep2" onChange={(e) => { onTodoRegChange(e) }} />

                      </div>
                    </div>

                  </div>
                  <div className="row mt-5 align-items-center">
                    <div className="col-lg-10">
                      <p className="mb-0 tx-12">To learn more about how we use your information, read our Privacy Policy.</p>
                    </div>
                    <div className="col-lg-2">
                      <button type="button" className="btn btn-full btn-md btn-primary" onClick={() => { onSubmitForm(2) }}>{buttonLoader ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : ("Next")}</button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {Step === 3 && (
              <div className="tradebox">
                <div className="tradebox-inner">
                  <div className="row g-3 mt-4">
                    <div className="col-lg-12">
                      <div className="form-group mb-10">
                        <label>Aadhar Verification <span className="required">*</span></label>
                        <input type="number" className="requiredstep3" placeholder="Enter last four digits of aadharcard" name="adharnumber" onChange={(e) => { onTodoRegChange(e) }} />
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 mt-4">
                    <p>Please provide company website link , company social media link or upload a buisness card that includes the applicant name <span className="required">*</span></p>
                    <div className="col-lg-6">
                      <div className="form-group mb-10">
                        <label className="required">Comapny website or social media link</label>
                        <input type="text" placeholder="Comapny website" name="weblink" onChange={(e) => { onTodoRegChange(e) }} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-10">
                        <label>Upload Buisness Card</label>
                        <input type="file" name="buisness_card" onChange={(e) => { handleSingleImageUpload(e, 'card') }} />
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 mt-4">
                    <p>Please provide a copy of Buisness Licence/Resale Certificate or GST number  <span className="required">*</span></p>
                    <div className="col-lg-6">
                      <div className="form-group mb-10">
                        <label>Upload buisness licence </label>
                        <input type="file" placeholder="Buisness Licence" name="buisness_liecence" onChange={(e) => { handleSingleImageUpload(e, 'licence') }} />

                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-10">
                        <label>GST Number</label>
                        <input type="number" name="gst_number" onChange={(e) => { onTodoRegChange(e) }} />

                      </div>
                    </div>

                  </div>
                  <div className="row mt-5 align-items-center">
                    <div className="col-lg-10">
                      <p className="mb-0 tx-12">To learn more about how we use your information, read our Privacy Policy.</p>
                    </div>
                    <div className="col-lg-2">
                      <button type="button" className="btn btn-full btn-md btn-primary" onClick={() => { onSubmitForm(3) }}>{buttonLoader ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : ("Next")}</button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {Step === 4 && (
              <div className="tradebox">
                <div className="tradebox-inner">

                  <div className="row g-3 mt-4">
                    <div className="col-lg-12">
                      <div className="form-group mb-10">
                        <label>Product Collection <span className="required">*</span></label>
                        <select name="product_collection" onChange={(e) => onTodoRegChange(e)} className="form-control requiredstep4">
                          <option value="" >Select Product Collection</option>
                          <option value="1">Residentail </option>
                          <option value="2">Commercial </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-10">
                        <label>
                          Product Category <span className="required">*</span>
                        </label>
                        <select
                          name="product_category"
                          onChange={(e) => onTodoCateChange(e)}
                          multiple
                          className="form-control requiredstep4"
                          value={tradeProgram.product_category || []}
                        >
                          <option value="" disabled>
                            Select Category
                          </option>
                          {allCategory &&
                            allCategory.map((item, index) => (
                              <option value={item?.cat_id} key={index}>
                                {item?.cat_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 mt-4">
                    <div className="col-lg-12">
                      <div className="form-group mb-10">
                        <label className="required">Moq Quantity</label>
                        <input type="number" placeholder="MOQ" name="moq" className="requiredstep4" onChange={(e) => { onTodoRegChange(e) }} />

                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-10">
                        <label className="required" >Comment Box</label>
                        <textarea type="text" name="comment_box" className="requiredstep4" rowSpan='4' onChange={(e) => { onTodoRegChange(e) }} />
                      </div>
                    </div>

                  </div>

                  <div className="row mt-5 align-items-center">
                    <div className="col-lg-10">
                      <p className="mb-0 tx-12">To learn more about how we use your information, read our Privacy Policy.</p>
                    </div>
                    <div className="col-lg-2">
                      <button type="button" className="btn btn-full btn-md btn-primary" onClick={() => { onSubmitForm(4) }}>{buttonLoader ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : ("Submit")}</button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {Step === 5 && (
              <div className="tradebox">
                <div className="tradebox-inner">

                  <div className="row g-3 mt-4">
                    <div className="col-lg-12">
                      <div className=" mb-15 tx-center"><img src="/img/verify_mail.png" className="wd-200 mb-20" />
                        <h2 style={{ textAlign: 'center' }}>Thank you for Register </h2>
                        <p style={{ textAlign: 'center' }}>You have been registered on RukmanCreations now,  you explore more with us.</p>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5 align-items-center">
                    <div className="col-lg-8">
                      <p className="mb-0 tx-12">To learn more about how we use your information, read our Privacy Policy.</p>
                    </div>
                    <div className="col-lg-4">
                      <button type="button" className="btn btn-full btn-md btn-primary" onClick={() => { window.location.href = '/' }}>Go To Home</button>
                    </div>
                  </div>

                </div>
              </div>
            )}

          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </>)
}


export default TradeLogin