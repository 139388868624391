import { useNavigate } from "react-router-dom"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { toast } from "react-toastify"
import CartHeader from "../../Components/Header/cart_header"
import Accordion from 'react-bootstrap/Accordion';
import { useContext, useState } from "react";
import DataContext from "../../Components/Elements/context";
import { ApiService } from "../../Components/Services/apiservices";
import React, { useEffect, useRef, useCallback } from 'react';
import sessionCartData from "../../Components/Elements/cart_session_data";
import { addToCart, minusToCart, minusToCartSession, removeToCart, removeToCartSession, addToCartSession } from "../../Components/Elements/add_to_cart";
import constant from "../../Components/Services/constant"

const Cart = () => {
    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const dataArray = sessionCartData();
    const [isLoading, setisLoading] = useState(false)
    const didMountRef = useRef(true)
    const contextValues = useContext(DataContext)
    const sliderRef = useRef(null);
    useEffect(() => {
        if (didMountRef.current) {
            if (localStorage.getItem("USER_TOKEN")) {
                getCartSessionData();
            } else {
                contextValues.setCartSessionData(dataArray[1])
                contextValues.setCartCount(dataArray[1].length)
                contextValues.setCartSummary(dataArray[3])
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            }

        }
        didMountRef.current = false;
    }, []);
    const navigate = useNavigate()
    const getCartSessionData = () => {
        setSpinnerLoading(true)
        const dataString = {
            coupon_session: localStorage.getItem("COUPON_SESSION"),
        };
        ApiService.postData("cartSessionData", dataString).then((res) => {
            if (res.data.status === "success") {
                contextValues.setCartSessionData(res.data.resCartData)
                contextValues.setCartCount(res.data.resCartData.length)
                contextValues.setCartSummary(res.data.cartSummary)
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            } else {
                contextValues.setCartSessionData([])
                contextValues.setCartCount(0)
                contextValues.setCartSummary({})
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            }
        }).catch(() => {
            setSpinnerLoading(false)
        });
    }

    const discount = (value) => {

        var discountPrice = (value.product_price - value.product_selling_price) / value.product_price
        discountPrice = Math.round(discountPrice * 100)
        return discountPrice
    }
 

    const addToCartProcess = async (addproduct) => {
        if (parseFloat(addproduct.product_selling_price) > 0) {
            const productData = {
                product_id: Number(addproduct.product_id),
                product_name: addproduct.product_name,
                product_slug: addproduct.product_slug,
                product_image: addproduct.product_image,
                product_type: Number(addproduct.product_type),
                product_price: parseFloat(addproduct.product_price).toFixed(2),
                product_selling_price: parseFloat(addproduct.product_selling_price).toFixed(2),
                product_discount: addproduct.product_discount,
                product_variation: addproduct.product_variation,
                product_category_id: addproduct.product_category_id,
                selected_variation: addproduct.selected_variation,
                quantity: addproduct.quantity,
                product_variation_id: addproduct?.product_variation_id ? addproduct?.product_variation_id : null,

            };
            contextValues.setSpinnerCubLoader(addproduct.product_id)
            if (localStorage.getItem("USER_TOKEN")) {
                const updateStatus = await addToCartSession(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await addToCart(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };

    const minusToCartProcess = async (addproduct) => {
        if (parseFloat(addproduct.product_selling_price) > 0) {
            contextValues.setSpinnerCubLoader(addproduct.product_id)
            if (localStorage.getItem("USER_TOKEN")) {
                const updateStatus = await minusToCartSession(addproduct, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await minusToCart(addproduct, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };

    const removeToCartProcess = async (productData) => {
        setSpinnerLoading(false);
        if (localStorage.getItem("USER_TOKEN")) {
            const updateStatus = await removeToCartSession(productData, contextValues);
            if (updateStatus) {
                setSpinnerLoading(false);
            } else {
                setSpinnerLoading(false);
            }
        } else {
            const updateStatus = await removeToCart(productData, contextValues);
            if (updateStatus) {
                setSpinnerLoading(false);
            } else {
                setSpinnerLoading(false);
            }
        }
    };
    const addtofav = (addproduct) => {
        const productData = {
            product_id: Number(addproduct.product_id),
            product_name: addproduct.product_name,
            product_slug: addproduct.product_slug,
            product_image: addproduct?.product_image ? addproduct?.product_image : constant.DEFAULT_IMAGE,
            product_type: Number(addproduct.product_type),
            product_price: parseFloat(addproduct.product_price).toFixed(2),
            product_selling_price: parseFloat(addproduct.product_selling_price).toFixed(2),
            product_discount: addproduct.product_discount,
            product_variation: addproduct?.product_variation,
            product_category_id: addproduct.product_category_id,
            selected_variation: addproduct?.selected_variation,
            quantity: 1,
            upf_variation_id: addproduct?.product_variation_id ? addproduct?.product_variation_id : null,
        };
        ApiService.postData("add-to-fav", productData).then((res) => {
            if (res.data.status == "success") {
                // var element = document.getElementById("icon" + addproduct.product_id);
                let element
                if (addproduct?.product_variation_id) {
                    element = document.getElementById(addproduct?.product_variation_id);
                } else {
                    element = document.getElementById(addproduct?.product_id);

                }
                element.classList.remove("ri-heart-3-fill", "ri-heart-3-line");
                element.classList.add(res.data.notification);
                contextValues.setFavCount(res.data.count)
                if (res.data.notification === "ri-heart-3-line") {
                    toast.success('Removed from wishlist');
                } else {
                    toast.success('Added to Wishlist');
                }
                getCartSessionData()
            }
        });
    };


    return (<>
        <Header></Header>

       
         

                {contextValues.cartSessionData.length > 0 ?

                    <>
                     <div className="cartsection">
                       <div className="cartsection-left">
                        <div>
                            <h2>Your Cart</h2>
                            <p>Not quite ready to check out? <a href="/" className="linebutton">Continue Shopping.</a> </p>
                        </div>
                        {contextValues.cartSessionData && contextValues.cartSessionData.map((value, index) => {
                            return (<>
                                <div className="cartproductbox">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-12 d-flex flex-row">
                                                    <div className="item-image">
                                                        <a href={`/product/${value.product_slug}`}>
                                                            <img src={value?.product_image}></img>
                                                        </a>
                                                    </div>
                                                    <div className="item-attributes d-flex flex-column">
                                                        <p className="brand tx-12 mb-0">Knoll</p>
                                                        <h2 className="title"><a href={`/product/${value?.product_slug}`}>{value?.product_name}</a></h2>
                                                        {value?.product_type == 1 && value?.selected_variation && value?.selected_variation.length > 0 ?
                                                            value?.selected_variation.map((item, index) => {
                                                                return (
                                                                    <p className="tx-gray tx-12 mb-1" key={index}><strong>{item?.attr}:</strong> {item.terms}</p>
                                                                )
                                                            })
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="qqty">
                                                        <span className="qqa" onClick={() => { minusToCartProcess(value) }} ><i class="ri-subtract-line"></i></span>
                                                        <span>{value?.quantity}</span>
                                                        <span className="qqa" onClick={() => { addToCartProcess(value) }}><i class="ri-add-line"></i></span>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="cartPrice">
                                                        {Number(value.product_price) > Number(value.product_selling_price) && (<del className="old-price">₹ {value.product_price}</del>)}
                                                        <ins class="new-price">₹{Number(value.product_selling_price)}</ins>
                                                        {discount(value) > 0 && <span className="tx-12 tx-green">{discount(value)}% Off</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="cartproductbox-footer">
                                                {contextValues.userToken ? (
                                                    value?.user_fav_product > 0 ? (

                                                        <a href="javascript:void(0)" onClick={(e) => addtofav(value)}><i className="ri-heart-3-fill" id={value?.product_variation_id ? value?.product_variation_id : value.product_id}></i>Remove from Favorites</a>
                                                    ) : (
                                                        <a href="javascript:void(0)" onClick={(e) => addtofav(value)}><i className="ri-heart-3-line" id={value?.product_variation_id ? value?.product_variation_id : value.product_id}></i>Add to Favorites</a>
                                                    )
                                                ) : (
                                                    <a href="javascript:void(0)" onClick={(e) => { navigate('/login') }}><i className="ri-heart-3-line"></i>Add to Favorites</a>
                                                )
                                                }
                                                {/* <a href="javascript:void(0)"><i class="ri-heart-3-line"></i>Add to Favorites </a> */}
                                                <a href="javascript:void(0)" onClick={() => { removeToCartProcess(value) }}><i class="ri-delete-bin-6-line"></i> Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>)
                        })}
                        <div className="row justify-content-end">
                            <div className="col-lg-6">
                                <div className="cartpricebox mb-20">
                                    <h4 className="mb-20">Order Summary</h4>
                                    <ul>
                                        <li><span>Subtotal</span><span>₹{contextValues.cartSummary.itemTotal}</span></li>
                                        <li><span>Shipping</span><span className="tx-green">Shipping calculated at next step</span></li>
                                        <li className="tx-green"><span>Total Savings</span><span>-₹{contextValues.cartSummary.discount}</span></li>
                                        {/* <li><span>Subtotal</span><span>₹5500.00</span></li> */}
                                        <hr></hr>
                                        <li className="fw700 tx-16"><span>TOTAL</span><span>₹{contextValues.cartSummary.total_amount}</span></li>
                                    </ul>
                                </div>
                                <button type="button" className="btn btn-primary btn-full btn-md" onClick={() => { navigate('/cart/address') }}>Checkout</button>
                            </div>
                        </div>
                        <div className="orderinfo mt-50">
                            <h2>Order Information</h2>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Return Policy</Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            We want you to be delighted with your new purchase. If you need to make a return, we're here to help. All sales are final on Clearance items that display a “final sale” badge. Please review your item(s) upon delivery. If there is an
                                            issue with your order, contact your local DWR Studio or our customer service team at +91 96495 66777 or <a href="">sales@rukmancreations.in</a> within three (3) days of receipt. If you receive a damaged, defective, or
                                            incorrect item, including Clearance items, we’ll make it right. Visit our <a href="">Returns + Exchanges</a> page for details.
                                        </p>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Shipping Options</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Because of the variety of items we sell, our shipping methods are determined by item type. Visit our <a href="">Shipping + Delivery</a> page for details.</p>

                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Measuring Of Delivery</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Learn how to measure and prepare for delivery using our <a href="">In-Home Delivery Guidelines</a>.</p>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>International Orders</Accordion.Header>
                                    <Accordion.Body>
                                        <p>We ship to the US, Canada, and Puerto Rico. For complete shipping details, visit our <a href="https://www.dwr.com/customerservice-shipping-delivery.html?lang=en_US">Shipping + Delivery</a> page.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        </div>
                        <div className="cartsection-right">
                <h2 className="mb-20">Complete the Space</h2>
                <div className="cspaceSlider">
                    <div className="cspaceProduct">
                        <div className="cspaceProductMedia">
                            <a href="#">
                                <img src="https://rukmancreations.codexotech.in/csadmin/public/img/uploads/media/6040391727355266.jpg"></img>
                            </a>
                        </div>
                        <div className="cspaceProductContent">
                            <h2 className="title">Line Wine Bar</h2>
                            <div className="cspace-price">
                                <ins className="new-price">₹5500.00</ins>
                                <del className="old-price">₹6700.00</del>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cspaceSlider">
                    <div className="cspaceProduct">
                        <div className="cspaceProductMedia">
                            <a href="#">
                                <img src="https://rukmancreations.codexotech.in/csadmin/public/img/uploads/media/6040391727355266.jpg"></img>
                            </a>
                        </div>
                        <div className="cspaceProductContent">
                            <h2 className="title">Line Wine Bar</h2>
                            <div className="cspace-price">
                                <ins className="new-price">₹5500.00</ins>
                                <del className="old-price">₹6700.00</del>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
                    </>
                    :
                    <div >
                       
                        <div className='noimg tx-center'>
                            <img src='/img/emptycart.png' alt='cart' className="wd-200" ></img>
                            <h5>Your Cart is Empty</h5>
                            <p>It looks like you haven't added any items to your cart yet.</p>
                            <a href='/' className='btn btn-primary  btn-lg mb-5'>Continue Shopping</a>
                        </div>
                    </div>}

          
           
     

        <Footer></Footer>
    </>)
}

export default Cart