
import Modal from 'react-bootstrap/Modal';
import React, { useContext, useState, useEffect, useRef } from 'react';
import DataContext from '../Elements/context';

const CartModal = () => {
    const contextValues = useContext(DataContext)
    const cartModal = () => {
        contextValues.setToggleCartModal(!contextValues.toggleCartModal)
    }
    return (<>
        <Modal show={contextValues.toggleCartModal} onHide={(e) => cartModal()} className='right cartModal'>
        
        <div className='modal-header'>
        <h2 className="modal-title mb-0">Shopping Cart</h2>
        <button className='modal-close'  onClick={() => { cartModal() }}><i className="ri-close-line"></i></button>
        </div>
        <div className='modal-footer'>
        <div className="cartbox-total mb-20">
                    <div>
                        <p className="mb-0">Estimated Total </p>
                        <p className="mb-0 tx-12 tx-gray-500">Discount code to be applied at checkout.</p>
                    </div>
                    <span className="total-price">₹699.00</span>
                </div>
                <a href="/cart" className="cartbox-action-btn">View Cart</a>
                <a href="/checkout" className="btn btn-primary btn-lg btn-full" type="button">Go to Checkout</a>
        </div>
        </Modal>
    </>)
}


export default CartModal