import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { useNavigate, useParams } from "react-router-dom"
import { useState, useRef, useEffect } from "react"
import { ApiService } from "../../Components/Services/apiservices"


const VerifyTradeEmail=()=>{
    const navigate= useNavigate()
    const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const id = urlParams.get('id')
         
  
    const [successmessage, setsuccessmessage] = useState('')
    const [errormessage, seterrormessage] = useState('')
    const didMountRef = useRef(true)
    useEffect(() => {
        if (didMountRef.current) {
            emailVerify()
        }
        didMountRef.current = false
    },[])

    const emailVerify = () => {
        const dataString = {
            token: id,
            user_type:2
        }
        ApiService.loginProccessPostData('emailverify', dataString).then((res) => {
            if (res.status == 'success') {
                setsuccessmessage(res.message)
            } else {
                seterrormessage(res.message)
            }
        })
    }
    return(<>
    <Header></Header>

    <section className="section-gap-medium">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="loginbox mb-20">
                        <div className="text-center mb-20">
                                <h4>Verify Email</h4>
                            </div>
                            <div className="text-center align-item-center">
                                {successmessage&& (<>
                                    <img src='/img/verified.gif'></img>
                                <h2>Account Verified</h2>
                                <p>Thank you for confirming your email. Your account is verified now . Start purhasing your shopping from <a href='/'>here</a></p>
                                
                                </>)}
                                {errormessage && <>
                                    <p>{errormessage}</p>
                                </>}
                            </div>
                            <button className="btn btn-lg btn-primary btn-full mb-20 mt-20" onClick={() => { navigate('/') }}> Shop Now</button>
                        </div>
                      
                    </div>
                   
                </div>
            </div>
        </section>
    <Footer></Footer>
    </>)
}

export default VerifyTradeEmail