import { BrowserView, MobileView } from "react-device-detect"
import { useNavigate } from "react-router-dom"

const HomeAppointment=()=>{
    const navigate = useNavigate()
    return(<>
    <BrowserView>
    <section className="section-book">
            <button type="button">
                <div className="button-border-top"></div>
                <div className="button-border-bottom"></div>
                <div className="button-text" onClick={()=>{navigate('/book-an-appointment')}}>
                    <span>.</span>
                    <span>Book a Visit</span>
                    <span>.</span>
                    <span>Book a Visit</span>
                    <span>.</span>
                    <span>Book a Visit</span>
                    <span>.</span>
                    <span>Book a Visit</span>
                    <span>.</span>
                    <span>Book a Visit</span>
                    <span>.</span>
                </div>
            </button>
        </section>
    
    
    </BrowserView>
   <MobileView>

   <section className="section-book">
            <button type="button">
                <div className="button-border-top"></div>
                <div className="button-border-bottom"></div>
                <div className="button-text" onClick={()=>{navigate('/book-an-appointment')}}>
                    <span>.</span>
                    <span>Book a Visit</span>
                    <span>.</span>
                    <span>Book a Visit</span>
                </div>
            </button>
        </section>
   </MobileView>

    
    </>)
}


export default HomeAppointment