import { ApiService } from "../../Components/Services/apiservices";
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import { validEmail , validNumber } from "../../Components/Elements/Regex";
import DataContext from "../../Components/Elements/context";


const ContactForm=()=>{
    const [errorMessage, setErrorMessage] = useState("");
    const [buttonLoader, setButtonLoader] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [step, setStep] = useState(1);
    const [contactData, setContactData] = useState({
        contact_name: "",
        contact_email: "",
        contact_mobile:'',
        contact_message:''
    })

    const handleInputChange = (e) => {
        setErrorMessage('')
        setSuccessMessage('')
        const { name, value } = e.target;
        setContactData(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
        if (value !== '') {
            e.target.style.border = '';
        }
    };

    const handleSubmit = () => {

        let counter = 0;
        const contactElement = document.getElementsByClassName("contactRequired");
        for (let i = 0; i < contactElement.length; i++) {
            if (contactElement[i].value === '') {
                contactElement[i].style.border = '1px solid red';
                counter++;
            } else {
                contactElement[i].style.border = '';
            }
        }
     

        if (counter == 0) {
            if (!validEmail.test(contactData.contact_email)) {
                setErrorMessage('Invalid Email Format')
                return false;
            }
            if (!validNumber.test(contactData.contact_mobile)) {
                setErrorMessage('Invalid Mobile Number ')
                return false;
            }
            setButtonLoader(true)
            ApiService.postData("contactusprocess", contactData).then((res) => {
                if (res.status == "success") {
                    setStep(2)
                    setTimeout(() => {
                        setSuccessMessage(res.message);
                        setButtonLoader(false) 
                    }, 1000);
                    setTimeout(()=>{
                        setStep(1)
                    }, 3000)
                } else {
                    setButtonLoader(false)
                    setErrorMessage(res.message);
                }
            }).catch(()=>{
                setButtonLoader(false)
            })
        }
    }
    const contextValues= useContext(DataContext)

    return(<>
     <section className="section-gap-medium">
            <div className='container'>
                <div className='row justify-content-center'>
                    {step==1 && <div className='col-lg-6'>
                        <div className='section-title mb-40 text-center'>
                            <h2>Send an Enquiry</h2>
                        </div>
                        {errorMessage ? <Alert key={'danger'} className='danger' variant='danger'>{errorMessage}</Alert> : ''}
                     
                        <div className='form-group mb-15'>
                            <label>First Name</label>
                            <input type='text' name="contact_name" className="contactRequired" onChange={(e)=>{handleInputChange(e)}}></input>
                        </div>
                        <div className='form-group  mb-15'>
                            <label>Email</label>
                            <input type='email' name="contact_email" className="contactRequired"  onChange={(e)=>{handleInputChange(e)}}></input>
                        </div>
                        <div className='form-group  mb-15'>
                            <label>Phone</label>
                            <input type='number' name="contact_mobile" className="contactRequired" min={0} onChange={(e)=>{handleInputChange(e)}}></input>
                        </div>
                        <div className='form-group  mb-15'>
                            <label>Message</label>
                            <textarea type='text' rows={4} name="contact_message" className="contactRequired"  onChange={(e)=>{handleInputChange(e)}}></textarea>
                        </div>
                        <button type="button" class="btn btn-primary btn-md" disabled={buttonLoader} onClick={() => { handleSubmit() }}> {buttonLoader ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : ("Submit Enquiry")} <i class="ri-arrow-right-line"></i></button>
                    </div>}
                    {step==2 && <>
                        <h2 className="text-center">Thank You for submitting</h2>
                        <p className="text-center">Your message has been sent. We will get back to you soon!</p>
                    </>}
                </div>
            </div>
        </section>
    
    </>)
}

export default ContactForm