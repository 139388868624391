import Skeleton from "react-loading-skeleton";
import constant from "../Services/constant";
const Menu = ({ imageUrl, isLoading, menuData }) => {
    
    return (<>
        <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
                <ul className="navbar-nav mx-auto align-items-center">
                    {isLoading ? <>

                        {[...Array(7)].map((_, index) => (
                            <li
                                className="nav-item dropdown dropdown-hover"
                                key={index}
                            >
                                <a
                                    className="nav-link"
                                    href="javascript:void(0)"
                                    id="navbarDropdown"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >

                                    <span><Skeleton width={'80px'}></Skeleton></span>
                                </a>

                            </li>
                        ))}

                    </> : <>   {menuData.length > 0
                        ? menuData.map((parent, indexParent) => {
                            if (parent.menu_mega === 0) {
                                if (parent?.children?.length > 0) {
                                    if (parent?.menu_categoryid > 0 && parent?.menu_categoryid && parent?.menu_categoryid?.cat_slug) {
                                        return (
                                            <li
                                                className="nav-item dropdown dropdown-hover"
                                                key={indexParent}
                                            >
                                                <a
                                                    className="nav-link"
                                                    href={
                                                        "/category/" +
                                                        parent?.categories?.cat_slug
                                                    }
                                                    id="navbarDropdown"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <span>{parent.menu_name}</span>
                                                </a>
                                                <ul
                                                    className="dropdown-menu submenu"
                                                    aria-labelledby="navbarDropdown"
                                                >
                                                    {parent?.children.map(
                                                        (firstChild, indexFirstChild) => {
                                                            if (firstChild?.menu_categoryid > 0 && firstChild?.categories && firstChild?.categories?.cat_slug) {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        {/* <h6><a href="#"> {firstChild.menu_name}</a></h6> */}
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={
                                                                                "/category/" +
                                                                                firstChild?.categories?.cat_slug
                                                                            }
                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            }
                                                            if (firstChild?.menu_tagid > 0 && firstChild?.tags && firstChild?.tags?.tag_slug) {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        {/* <h6><a href="#"> {firstChild.menu_name}</a></h6> */}
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={
                                                                                "/tag/" +
                                                                                firstChild?.tags?.tag_slug
                                                                            }
                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            }
                                                            else if (firstChild?.menu_collectionid > 0 && firstChild?.collection && firstChild?.collection?.cat_slug) {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={`/collection/${firstChild?.collection?.cat_slug}`}
                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            }

                                                            else if (firstChild?.menu_pageid > 0 && firstChild?.pages && firstChild?.pages?.page_url) {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={"/" + firstChild?.pages?.page_url}
                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            } else {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={firstChild?.menu_customlink}
                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            }
                                                        }
                                                    )}
                                                </ul>
                                            </li>
                                        );
                                    } 
                                    else if (parent?.menu_tagid > 0 && parent?.tags && parent?.tags?.tag_slug) {
                                        return (
                                            <li
                                                className="nav-item dropdown dropdown-hover"
                                                key={indexParent}
                                            >
                                                <a
                                                    className="nav-link"
                                                    href={`/tag/${parent?.tags?.tag_slug}`}
                                                    id="navbarDropdown"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <span>{parent?.menu_name}</span>
                                                </a>
                                                <ul
                                                    className="dropdown-menu submenu"
                                                    aria-labelledby="navbarDropdown"
                                                >
                                                    {parent?.children.map(
                                                        (firstChild, indexFirstChild) => {
                                                            if (firstChild?.menu_categoryid > 0 && firstChild?.categories && firstChild?.categories?.cat_slug) {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={
                                                                                "/category/" +
                                                                                firstChild?.categories?.cat_slug
                                                                            }
                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            } else if (firstChild?.menu_collectionid > 0 && firstChild?.collection && firstChild?.collection?.cat_slug) {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={`/collection/${firstChild?.collection?.cat_slug}`}

                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            }
                                                            else if (firstChild?.menu_tagid > 0 && firstChild?.tags && firstChild?.tags?.tag_slug) {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={`/tag/${firstChild?.tags?.tag_slug}`}

                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            }

                                                            else if (firstChild?.menu_pageid > 0 && firstChild?.pages && firstChild?.pages?.page_url) {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={"/" + firstChild?.pages?.page_url}
                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            } else {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={firstChild?.menu_customlink}
                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            }
                                                        }
                                                    )}
                                                </ul>
                                            </li>
                                        );
                                    }
                                    else if (parent?.menu_collectionid > 0 && parent?.collection && parent?.collection?.cat_slug) {
                                        return (
                                            <li
                                                className="nav-item dropdown dropdown-hover"
                                                key={indexParent}
                                            >
                                                <a
                                                    className="nav-link"
                                                    href={`/collection/${parent?.collection?.cat_slug}`}
                                                    id="navbarDropdown"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <span>{parent?.menu_name}</span>
                                                </a>
                                                <ul
                                                    className="dropdown-menu submenu"
                                                    aria-labelledby="navbarDropdown"
                                                >
                                                    {parent?.children.map(
                                                        (firstChild, indexFirstChild) => {
                                                            if (firstChild?.menu_categoryid > 0 && firstChild?.categories && firstChild?.categories?.cat_slug) {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={
                                                                                "/category/" +
                                                                                firstChild?.categories?.cat_slug
                                                                            }
                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            } else if (firstChild?.menu_collectionid > 0 && firstChild?.collection && firstChild?.collection?.cat_slug) {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={`/collection/${firstChild?.collection?.cat_slug}`}

                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            }
                                                            else if (firstChild?.menu_tagid > 0 && firstChild?.tags && firstChild?.tags?.tag_slug) {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={`/tag/${firstChild?.tags?.tag_slug}`}

                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            }

                                                            else if (firstChild?.menu_pageid > 0 && firstChild?.pages && firstChild?.pages?.page_url) {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={"/" + firstChild?.pages?.page_url}
                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            } else {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={firstChild?.menu_customlink}
                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            }
                                                        }
                                                    )}
                                                </ul>
                                            </li>
                                        );
                                    }


                                    else if (parent?.menu_pageid > 0 && parent?.pages && parent?.pages?.page_url) {
                                        return (
                                            <li
                                                className="nav-item dropdown dropdown-hover"
                                                key={indexParent}
                                            >
                                                <a
                                                    className="nav-link"
                                                    href={"/" + parent?.pages?.page_url}
                                                    id="navbarDropdown"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <span>{parent?.menu_name}</span>
                                                </a>
                                                <ul
                                                    className="dropdown-menu submenu"
                                                    aria-labelledby="navbarDropdown"
                                                >
                                                    {parent?.children.map(
                                                        (firstChild, indexFirstChild) => {
                                                            if (firstChild?.menu_categoryid > 0 && firstChild?.categories && firstChild?.categories?.cat_slug) {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={
                                                                                "/category/" +
                                                                                firstChild?.categories?.cat_slug
                                                                            }
                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            } 
                                                            else if (firstChild?.menu_collectionid > 0 && firstChild?.collection && firstChild?.collection?.cat_slug) {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={`/collection/${firstChild?.collection?.cat_slug}`}

                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            }
                                                            else if (firstChild?.menu_tagid > 0 && firstChild?.tags && firstChild?.tags?.tag_slug) {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={`/tag/${firstChild?.tags?.tag_slug}`}

                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            }

                                                            else if (firstChild?.menu_pageid > 0 && firstChild?.pages && firstChild?.pages?.page_url) {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={"/" + firstChild?.pages?.page_url}
                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            } else {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={firstChild?.menu_customlink}
                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            }
                                                        }
                                                    )}
                                                </ul>
                                            </li>
                                        );
                                    } else {
                                        return (
                                            <li
                                                className="nav-item dropdown dropdown-hover"
                                                key={indexParent}
                                            >
                                                <a
                                                    className="nav-link"
                                                    href={parent?.menu_customlink}
                                                    id="navbarDropdown"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <span>{parent?.menu_name}</span>
                                                </a>
                                                <ul
                                                    className="dropdown-menu submenu"
                                                    aria-labelledby="navbarDropdown"
                                                >
                                                    {parent?.children.map(
                                                        (firstChild, indexFirstChild) => {
                                                            if (firstChild?.menu_categoryid > 0 && firstChild?.categories && firstChild?.categories?.cat_slug) {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={
                                                                                "/category/" +
                                                                                firstChild?.categories?.cat_slug
                                                                            }
                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            }
                                                            else if (firstChild.menu_collectionid > 0 && firstChild?.collection && firstChild?.collection?.cat_slug) {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={`/collection/${firstChild?.collection?.cat_slug}`}

                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            }
                                                            else if (firstChild.menu_tagid > 0 && firstChild?.tags && firstChild?.tags?.tag_slug) {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={`/tag/${firstChild?.tags?.tag_slug}`}

                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            }
                                                            else if (firstChild?.menu_pageid > 0 && firstChild?.pages && firstChild?.pages?.page_url) {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={"/" + firstChild?.pages?.page_url}
                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            } else {
                                                                return (
                                                                    <li key={indexFirstChild}>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href={firstChild?.menu_customlink}
                                                                        >
                                                                            {firstChild?.menu_name}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            }
                                                        }
                                                    )}
                                                </ul>
                                            </li>
                                        );
                                    }
                                } else {
                                    if (parent?.menu_categoryid > 0 && parent?.categories && parent?.categories?.cat_slug) {
                                        return (
                                            <li className="nav-item" key={indexParent}>
                                                <a
                                                    className="nav-link"
                                                    aria-current="page"
                                                    href={
                                                        "/category/" +
                                                        parent?.categories?.cat_slug
                                                    }
                                                >
                                                    <span>{parent?.menu_name}</span>
                                                </a>
                                            </li>
                                        );
                                    }
                                    if (parent?.menu_tagid > 0 && parent?.tags && parent?.tags?.tag_slug) {
                                        return (
                                            <li className="nav-item" key={indexParent}>
                                                <a
                                                    className="nav-link"
                                                    aria-current="page"
                                                    href={
                                                        "/tag/" +
                                                        parent?.tags?.tag_slug
                                                    }
                                                >
                                                    <span>{parent?.menu_name}</span>
                                                </a>
                                            </li>
                                        );
                                    }
                                    else if (parent?.menu_collectionid > 0 && parent?.collection && parent?.collection?.cat_slug) {
                                        return (
                                            <li className="nav-item" key={indexParent}>
                                                <a
                                                    className="nav-link"
                                                    aria-current="page"
                                                    href={`/collection/${parent?.collection?.cat_slug}`}

                                                >
                                                    <span>{parent?.menu_name}</span>
                                                </a>
                                            </li>
                                        );
                                    }
                                    else if (parent?.menu_pageid > 0 && parent?.pages && parent?.pages?.page_url) {
                                        return (
                                            <li className="nav-item" key={indexParent}>
                                                <a
                                                    className="nav-link"
                                                    aria-current="page"
                                                    href={"/" + parent?.pages?.page_url}
                                                >
                                                    <span>{parent?.menu_name}</span>
                                                </a>
                                            </li>
                                        );
                                    } else {
                                        return (
                                            <li className="nav-item" key={indexParent}>
                                                <a
                                                    className="nav-link"
                                                    aria-current="page"
                                                    href={parent?.menu_customlink}
                                                >
                                                    <span>{parent?.menu_name}</span>
                                                </a>
                                            </li>
                                        );
                                    }
                                }
                            } else {
                                if (parent?.children?.length > 0) {
                                    if (parent?.menu_categoryid > 0 && parent?.categories && parent?.categories?.cat_slug) {
                                        return (
                                            <li
                                                className="nav-item dropdown mega-dropdown dropdown-hover"
                                                key={indexParent}
                                            >
                                                <a
                                                    className="nav-link"
                                                    href={
                                                        "/category/" +
                                                        parent?.categories?.cat_slug
                                                    }
                                                    id="navbarDropdown"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <span>{parent?.menu_name}</span>
                                                </a>
                                                <div
                                                    className="mega-menu dropdown-menu"
                                                    aria-labelledby="navbarDropdown"
                                                >
                                                    <div className="container">
                                                        <div className="row g-3">
                                                            {parent?.children.map(
                                                                (firstChild, indexFirstChild) => {
                                                                    if (firstChild?.menu_categoryid > 0 && firstChild?.categories && firstChild?.categories
                                                                        ?.cat_slug) {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                "/category/" +
                                                                                                firstChild?.categories
                                                                                                    ?.cat_slug
                                                                                            }
                                                                                        >
                                                                                            {firstChild?.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            "/category/" +
                                                                                            firstChild?.categories
                                                                                                ?.cat_slug
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } 
                                                                                                    
                                                                                                    else if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            ?.collection
                                                                                                        && thirdChild
                                                                                                            ?.collection
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            ?.collection
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            ?.tags
                                                                                                        && thirdChild
                                                                                                            ?.tags
                                                                                                            ?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            ?.tags
                                                                                                                            ?.tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }

                                                                    else if (firstChild?.menu_tagid > 0 && firstChild?.tags
                                                                        && firstChild?.tags
                                                                            ?.tag_slug) {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                "/collection/" +
                                                                                                firstChild?.collection
                                                                                                    ?.cat_slug
                                                                                            }
                                                                                        >
                                                                                            {firstChild?.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } 
                                                                        else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            "/tag/" +
                                                                                            firstChild?.tags
                                                                                                ?.tag_slug
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } 
                                                                                                    
                                                                                                    else if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            ?.collection
                                                                                                        && thirdChild
                                                                                                            ?.collection
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            ?.collection
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            ?.tags
                                                                                                        && thirdChild
                                                                                                            ?.tags
                                                                                                            ?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            ?.tags
                                                                                                                            ?.tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }
                                                                    else if (firstChild?.menu_collectionid > 0 && firstChild?.collection
                                                                        && firstChild?.collection
                                                                            ?.cat_slug) {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                "/collection/" +
                                                                                                firstChild?.collection
                                                                                                    ?.cat_slug
                                                                                            }
                                                                                        >
                                                                                            {firstChild?.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            "/collection/" +
                                                                                            firstChild?.collection
                                                                                                ?.cat_slug
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            ?.tags
                                                                                                        && thirdChild
                                                                                                            ?.tags
                                                                                                            ?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            ?.tags
                                                                                                                            ?.tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    
                                                                                                    
                                                                                                    else if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            ?.collection
                                                                                                        && thirdChild
                                                                                                            ?.collection
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            ?.collection
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }

                                                                    else if (firstChild?.menu_pageid > 0 && firstChild?.pages && firstChild?.pages?.page_url) {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                "/" +
                                                                                                firstChild?.pages?.page_url
                                                                                            }
                                                                                        >
                                                                                            {firstChild.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant?.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            "/" +
                                                                                            firstChild?.pages?.page_url
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            ?.collection
                                                                                                        && thirdChild
                                                                                                            ?.collection
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            ?.collection
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            ?.tags
                                                                                                        && thirdChild
                                                                                                            ?.tags
                                                                                                            ?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            ?.tags
                                                                                                                            ?.tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    } else {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                firstChild?.menu_customlink
                                                                                            }
                                                                                        >
                                                                                            {firstChild?.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            firstChild?.menu_customlink
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            ?.collection
                                                                                                        && thirdChild
                                                                                                            ?.collection
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            ?.collection
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            ?.tags
                                                                                                        && thirdChild
                                                                                                            ?.tags
                                                                                                            ?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            ?.tags
                                                                                                                            ?.tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }
                                                                }
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    }
                                    if (parent?.menu_tagid > 0 && parent?.tags && parent?.tags?.tag_slug) {
                                        return (
                                            <li
                                                className="nav-item dropdown mega-dropdown dropdown-hover"
                                                key={indexParent}
                                            >
                                                <a
                                                    className="nav-link"
                                                    href={
                                                        "/tag/" +
                                                        parent?.tags?.tag_slug
                                                    }
                                                    id="navbarDropdown"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <span>{parent?.menu_name}</span>
                                                </a>
                                                <div
                                                    className="mega-menu dropdown-menu"
                                                    aria-labelledby="navbarDropdown"
                                                >
                                                    <div className="container">
                                                        <div className="row g-3">
                                                            {parent?.children.map(
                                                                (firstChild, indexFirstChild) => {
                                                                    if (firstChild?.menu_categoryid > 0 && firstChild?.categories && firstChild?.categories
                                                                        ?.cat_slug) {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                "/category/" +
                                                                                                firstChild?.categories
                                                                                                    ?.cat_slug
                                                                                            }
                                                                                        >
                                                                                            {firstChild?.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            "/category/" +
                                                                                            firstChild?.categories
                                                                                                ?.cat_slug
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } 
                                                                                                    
                                                                                                    else if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            ?.collection
                                                                                                        && thirdChild
                                                                                                            ?.collection
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            ?.collection
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            ?.tags
                                                                                                        && thirdChild
                                                                                                            ?.tags
                                                                                                            ?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            ?.tags
                                                                                                                            ?.tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }

                                                                    else if (firstChild?.menu_tagid > 0 && firstChild?.tags
                                                                        && firstChild?.tags
                                                                            ?.tag_slug) {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                "/collection/" +
                                                                                                firstChild?.collection
                                                                                                    ?.cat_slug
                                                                                            }
                                                                                        >
                                                                                            {firstChild?.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } 
                                                                        else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            "/tag/" +
                                                                                            firstChild?.tags
                                                                                                ?.tag_slug
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } 
                                                                                                    
                                                                                                    else if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            ?.collection
                                                                                                        && thirdChild
                                                                                                            ?.collection
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            ?.collection
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            ?.tags
                                                                                                        && thirdChild
                                                                                                            ?.tags
                                                                                                            ?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            ?.tags
                                                                                                                            ?.tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }
                                                                    else if (firstChild?.menu_collectionid > 0 && firstChild?.collection
                                                                        && firstChild?.collection
                                                                            ?.cat_slug) {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                "/collection/" +
                                                                                                firstChild?.collection
                                                                                                    ?.cat_slug
                                                                                            }
                                                                                        >
                                                                                            {firstChild?.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            "/collection/" +
                                                                                            firstChild?.collection
                                                                                                ?.cat_slug
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            ?.tags
                                                                                                        && thirdChild
                                                                                                            ?.tags
                                                                                                            ?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            ?.tags
                                                                                                                            ?.tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    
                                                                                                    
                                                                                                    else if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            ?.collection
                                                                                                        && thirdChild
                                                                                                            ?.collection
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            ?.collection
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }

                                                                    else if (firstChild?.menu_pageid > 0 && firstChild?.pages && firstChild?.pages?.page_url) {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                "/" +
                                                                                                firstChild?.pages?.page_url
                                                                                            }
                                                                                        >
                                                                                            {firstChild.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant?.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            "/" +
                                                                                            firstChild?.pages?.page_url
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            ?.collection
                                                                                                        && thirdChild
                                                                                                            ?.collection
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            ?.collection
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            ?.tags
                                                                                                        && thirdChild
                                                                                                            ?.tags
                                                                                                            ?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            ?.tags
                                                                                                                            ?.tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    } else {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                firstChild?.menu_customlink
                                                                                            }
                                                                                        >
                                                                                            {firstChild?.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            firstChild?.menu_customlink
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            ?.collection
                                                                                                        && thirdChild
                                                                                                            ?.collection
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            ?.collection
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            ?.tags
                                                                                                        && thirdChild
                                                                                                            ?.tags
                                                                                                            ?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            ?.tags
                                                                                                                            ?.tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }
                                                                }
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    }
                                    else if (parent?.menu_collectionid > 0 && parent?.collection && parent?.collection?.cat_slug) {
                                        return (
                                            <li
                                                className="nav-item dropdown mega-dropdown dropdown-hover"
                                                key={indexParent}
                                            >
                                                <a
                                                    className="nav-link "

                                                    href={`/collection/${parent?.collection?.cat_slug}`}
                                                    id="navbarDropdown"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <span>{parent?.menu_name}</span>
                                                </a>
                                                <div
                                                    className="mega-menu dropdown-menu"
                                                    aria-labelledby="navbarDropdown"
                                                >
                                                    <div className="container">
                                                        <div className="row g-3">
                                                            {parent?.children.map(
                                                                (firstChild, indexFirstChild) => {
                                                                    if (firstChild?.menu_categoryid > 0 && firstChild.categories
                                                                        && firstChild.categories
                                                                            ?.cat_slug) {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                "/category/" +
                                                                                                firstChild.categories
                                                                                                    ?.cat_slug
                                                                                            }
                                                                                        >
                                                                                            {firstChild?.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            "/category/" +
                                                                                            firstChild?.categories
                                                                                                ?.cat_slug
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            ?.collection
                                                                                                        && thirdChild
                                                                                                            ?.collection
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            ?.collection
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            ?.tags
                                                                                                        && thirdChild
                                                                                                            ?.tags
                                                                                                            ?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            ?.tags
                                                                                                                            ?.tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }

                                                                    else if (firstChild?.menu_pageid > 0 && firstChild?.pages && firstChild?.pages?.page_url) {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                "/" +
                                                                                                firstChild?.pages?.page_url
                                                                                            }
                                                                                        >
                                                                                            {firstChild.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            "/" +
                                                                                            firstChild?.pages?.page_url
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            ?.collection
                                                                                                        && thirdChild
                                                                                                            ?.collection
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            ?.collection
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            ?.tags
                                                                                                        && thirdChild
                                                                                                            ?.tags
                                                                                                            ?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            ?.tags
                                                                                                                            ?.tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }
                                                                    else if (firstChild?.menu_collectionid > 0 && firstChild?.collection && firstChild?.collection?.cat_slug) {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a

                                                                                            href={`/collection/${firstChild?.collection?.cat_slug}`}
                                                                                        >
                                                                                            {firstChild?.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            "/" +
                                                                                            firstChild?.pages?.page_url
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            ?.collection
                                                                                                        && thirdChild
                                                                                                            ?.collection
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            ?.collection
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            ?.tags
                                                                                                        && thirdChild
                                                                                                            ?.tags
                                                                                                            ?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            ?.tags
                                                                                                                            ?.tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }
                                                                    else if (firstChild?.menu_tagid > 0 && firstChild?.tags && firstChild?.tags?.tag_slug) {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a

                                                                                            href={`/tag/${firstChild?.tags?.tag_slug}`}
                                                                                        >
                                                                                            {firstChild?.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            "/" +
                                                                                            firstChild?.pages?.page_url
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            ?.collection
                                                                                                        && thirdChild
                                                                                                            ?.collection
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            ?.collection
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            ?.tags
                                                                                                        && thirdChild
                                                                                                            ?.tags
                                                                                                            ?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            ?.tags
                                                                                                                            ?.tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }


                                                                    else {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                firstChild?.menu_customlink
                                                                                            }
                                                                                        >
                                                                                            {firstChild?.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant?.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            firstChild?.menu_customlink
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild?.collection && thirdChild?.collection?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a

                                                                                                                    href={`/collection/${thirdChild?.collection?.cat_slug}`}
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild?.tags && thirdChild?.tags?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a

                                                                                                                    href={`/tag/${thirdChild?.tags?.tag_slug}`}
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }
                                                                }
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    }

                                    else if (parent?.menu_pageid > 0 && parent?.pages && parent?.pages?.page_url) {
                                        return (
                                            <li
                                                className="nav-item dropdown mega-dropdown dropdown-hover"
                                                key={indexParent}
                                            >
                                                <a
                                                    className="nav-link "
                                                    href={"/" + parent?.pages?.page_url}
                                                    id="navbarDropdown"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <span>{parent?.menu_name}</span>
                                                </a>
                                                <div
                                                    className="mega-menu dropdown-menu"
                                                    aria-labelledby="navbarDropdown"
                                                >
                                                    <div className="container">
                                                        <div className="row g-3">
                                                            {parent?.children.map(
                                                                (firstChild, indexFirstChild) => {
                                                                    if (firstChild?.menu_categoryid > 0 && firstChild?.categories
                                                                        && firstChild?.categories
                                                                            ?.cat_slug) {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                "/category/" +
                                                                                                firstChild?.categories
                                                                                                    ?.cat_slug
                                                                                            }
                                                                                        >
                                                                                            {firstChild?.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant?.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            "/category/" +
                                                                                            firstChild?.categories
                                                                                                ?.cat_slug
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            ?.collection
                                                                                                        && thirdChild
                                                                                                            ?.collection
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            ?.collection
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            ?.tags
                                                                                                        && thirdChild
                                                                                                            ?.tags
                                                                                                            ?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            ?.tags
                                                                                                                            ?.tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }




                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }
                                                                    if (firstChild?.menu_tagid > 0 && firstChild?.tags
                                                                        && firstChild?.tags
                                                                            ?.tag_slug) {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                "/tag/" +
                                                                                                firstChild?.tags
                                                                                                    ?.tag_slug
                                                                                            }
                                                                                        >
                                                                                            {firstChild?.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant?.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            "/tag/" +
                                                                                            firstChild?.tags
                                                                                                ?.tag_slug
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            ?.collection
                                                                                                        && thirdChild
                                                                                                            ?.collection
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            ?.collection
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            ?.tags
                                                                                                        && thirdChild
                                                                                                            ?.tags
                                                                                                            ?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            ?.tags
                                                                                                                            ?.tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }




                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }


                                                                    else if (firstChild?.menu_collectionid > 0 && firstChild?.collection
                                                                        && firstChild?.collection
                                                                            ?.cat_slug) {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                "/collection/" +
                                                                                                firstChild?.collection
                                                                                                    ?.cat_slug
                                                                                            }
                                                                                        >
                                                                                            {firstChild?.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant?.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            "/collection/" +
                                                                                            firstChild?.collection
                                                                                                ?.cat_slug
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            ?.collection
                                                                                                        && thirdChild
                                                                                                            ?.collection
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            ?.collection
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }




                                                                                                    else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            ?.tags
                                                                                                        && thirdChild
                                                                                                            ?.tags
                                                                                                            ?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            ?.tags
                                                                                                                            ?.tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }


                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }

                                                                    else if (firstChild?.menu_pageid > 0 && firstChild?.pages && firstChild?.pages?.page_url) {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                "/" +
                                                                                                firstChild?.pages?.page_url
                                                                                            }
                                                                                        >
                                                                                            {firstChild?.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant?.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            "/" +
                                                                                            firstChild?.pages?.page_url
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            ?.collection
                                                                                                        && thirdChild
                                                                                                            ?.collection
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            ?.collection
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                   else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            ?.tags
                                                                                                        && thirdChild
                                                                                                            ?.tags
                                                                                                            ?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            ?.tags
                                                                                                                            ?.tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    } else {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                firstChild?.menu_customlink
                                                                                            }
                                                                                        >
                                                                                            {firstChild?.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant?.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            firstChild?.menu_customlink
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            ?.collection
                                                                                                        && thirdChild
                                                                                                            ?.collection
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            ?.collection
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            ?.tags
                                                                                                        && thirdChild
                                                                                                            ?.tags
                                                                                                            ?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            ?.tags
                                                                                                                            ?.tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }
                                                                }
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    }

                                    else {
                                        return (
                                            <li
                                                className="nav-item dropdown mega-dropdown dropdown-hover"
                                                key={indexParent}
                                            >
                                                <a
                                                    className="nav-link"
                                                    href={parent?.menu_customlink}
                                                    id="navbarDropdown"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <span> {parent?.menu_name}</span>
                                                </a>
                                                <div
                                                    className="mega-menu dropdown-menu"
                                                    aria-labelledby="navbarDropdown"
                                                >

                                                    <div className="container">
                                                        <div className="row g-3">
                                                            {parent?.children.map(
                                                                (firstChild, indexFirstChild) => {
                                                                    if (firstChild?.menu_categoryid > 0 && firstChild?.categories
                                                                        && firstChild?.categories
                                                                            ?.cat_slug) {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                "/category/" +
                                                                                                firstChild?.categories
                                                                                                    ?.cat_slug
                                                                                            }
                                                                                        >
                                                                                            {firstChild?.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant?.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                        else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            "/category/" +
                                                                                            firstChild?.categories
                                                                                                ?.cat_slug
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            ?.collection
                                                                                                        && thirdChild
                                                                                                            ?.collection
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            ?.collection
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            ?.tags
                                                                                                        && thirdChild
                                                                                                            ?.tags
                                                                                                            ?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            ?.tags
                                                                                                                            ?.tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }
                                                                    if (firstChild?.menu_tagid > 0 && firstChild?.tags
                                                                        && firstChild?.tags
                                                                            ?.tag_slug) {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                "/category/" +
                                                                                                firstChild?.categories
                                                                                                    ?.cat_slug
                                                                                            }
                                                                                        >
                                                                                            {firstChild?.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild?.menu_image
                                                                                                        : constant?.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                        else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            "/category/" +
                                                                                            firstChild?.categories
                                                                                                ?.cat_slug
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild?.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild?.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            ?.categories
                                                                                                        && thirdChild
                                                                                                            ?.categories
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            ?.categories
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild?.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            ?.collection
                                                                                                        && thirdChild
                                                                                                            ?.collection
                                                                                                            ?.cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            ?.collection
                                                                                                                            ?.cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            ?.tags
                                                                                                        && thirdChild
                                                                                                            ?.tags
                                                                                                            ?.tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            ?.tags
                                                                                                                            ?.tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild?.menu_pageid >
                                                                                                        0 && thirdChild?.pages
                                                                                                        && thirdChild?.pages
                                                                                                            ?.page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild?.pages
                                                                                                                            ?.page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild?.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild?.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }
                                                                    else if (firstChild?.menu_collectionid > 0 && firstChild?.collection && firstChild?.collection?.cat_slug) {
                                                                        if (firstChild?.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a

                                                                                            href={`/collection/${firstChild?.collection?.cat_slug}`}
                                                                                        >
                                                                                            {firstChild?.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild?.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild?.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild.menu_image
                                                                                                        : constant.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild?.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                        else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            "/collection/" +
                                                                                            firstChild?.collection?.cat_slug
                                                                                        } > {firstChild?.menu_name}</a></h6>

                                                                                        {firstChild?.children.length > 0 ? <>
                                                                                            <ul>
                                                                                                {firstChild.children.map(
                                                                                                    (
                                                                                                        thirdChild,
                                                                                                        indexThirdChild
                                                                                                    ) => {
                                                                                                        if (
                                                                                                            thirdChild?.menu_categoryid >
                                                                                                            0 && thirdChild
                                                                                                                ?.categories
                                                                                                            && thirdChild
                                                                                                                ?.categories
                                                                                                                ?.cat_slug
                                                                                                        ) {
                                                                                                            return (
                                                                                                                <li
                                                                                                                    key={
                                                                                                                        indexThirdChild
                                                                                                                    }
                                                                                                                >
                                                                                                                    <a
                                                                                                                        href={
                                                                                                                            "/category/" +
                                                                                                                            thirdChild
                                                                                                                                ?.categories
                                                                                                                                ?.cat_slug
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            thirdChild?.menu_name
                                                                                                                        }
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                            );
                                                                                                        }
                                                                                                        else if (
                                                                                                            thirdChild?.menu_collectionid >
                                                                                                            0 && thirdChild?.collection && thirdChild?.collection?.cat_slug
                                                                                                        ) {
                                                                                                            return (
                                                                                                                <li
                                                                                                                    key={
                                                                                                                        indexThirdChild
                                                                                                                    }
                                                                                                                >
                                                                                                                    <a

                                                                                                                        href={`/collection/${thirdChild?.collection?.cat_slug}`}
                                                                                                                    >
                                                                                                                        {
                                                                                                                            thirdChild?.menu_name
                                                                                                                        }
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                            );
                                                                                                        }

                                                                                                        else if (
                                                                                                            thirdChild?.menu_tagid >
                                                                                                            0 && thirdChild?.tags && thirdChild?.tags?.tag_slug
                                                                                                        ) {
                                                                                                            return (
                                                                                                                <li
                                                                                                                    key={
                                                                                                                        indexThirdChild
                                                                                                                    }
                                                                                                                >
                                                                                                                    <a

                                                                                                                        href={`/tag/${thirdChild?.tags?.tag_slug}`}
                                                                                                                    >
                                                                                                                        {
                                                                                                                            thirdChild?.menu_name
                                                                                                                        }
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                            );
                                                                                                        }
                                                                                                        else if (
                                                                                                            thirdChild?.menu_pageid >
                                                                                                            0 && thirdChild?.pages
                                                                                                            && thirdChild?.pages
                                                                                                                ?.page_url
                                                                                                        ) {
                                                                                                            return (
                                                                                                                <li
                                                                                                                    key={
                                                                                                                        indexThirdChild
                                                                                                                    }
                                                                                                                >
                                                                                                                    <a
                                                                                                                        href={
                                                                                                                            "/" +
                                                                                                                            thirdChild?.pages
                                                                                                                                ?.page_url
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            thirdChild.menu_name
                                                                                                                        }
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                            );
                                                                                                        } else {
                                                                                                            return (
                                                                                                                <li
                                                                                                                    key={
                                                                                                                        indexThirdChild
                                                                                                                    }
                                                                                                                >
                                                                                                                    <a
                                                                                                                        href={
                                                                                                                            thirdChild.menu_customlink
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            thirdChild.menu_name
                                                                                                                        }
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                            );
                                                                                                        }
                                                                                                    }
                                                                                                )}
                                                                                            </ul>




                                                                                        </> : ''}


                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }

                                                                    else if (firstChild.menu_pageid > 0 && firstChild?.pages && firstChild?.pages?.page_url) {
                                                                        if (firstChild.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                "/" +
                                                                                                firstChild?.pages?.page_url
                                                                                            }
                                                                                        >
                                                                                            {firstChild.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild.menu_image
                                                                                                        : constant.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                        else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            "/" +
                                                                                            firstChild?.pages?.page_url
                                                                                        } > {firstChild.menu_name}</a></h6>

                                                                                        <ul>
                                                                                            {firstChild.children.map(
                                                                                                (
                                                                                                    thirdChild,
                                                                                                    indexThirdChild
                                                                                                ) => {
                                                                                                    if (
                                                                                                        thirdChild.menu_categoryid >
                                                                                                        0 && thirdChild
                                                                                                            .categories
                                                                                                        && thirdChild
                                                                                                            .categories
                                                                                                            .cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/category/" +
                                                                                                                        thirdChild
                                                                                                                            .categories
                                                                                                                            .cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                    else if (
                                                                                                        thirdChild.menu_collectionid >
                                                                                                        0 && thirdChild
                                                                                                            .collection
                                                                                                        && thirdChild
                                                                                                            .collection
                                                                                                            .cat_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/collection/" +
                                                                                                                        thirdChild
                                                                                                                            .collection
                                                                                                                            .cat_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild.menu_tagid >
                                                                                                        0 && thirdChild
                                                                                                            .tags
                                                                                                        && thirdChild
                                                                                                            .tags
                                                                                                            .tag_slug
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/tag/" +
                                                                                                                        thirdChild
                                                                                                                            .tags
                                                                                                                            .tag_slug
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }

                                                                                                    else if (
                                                                                                        thirdChild.menu_pageid >
                                                                                                        0 && thirdChild.pages
                                                                                                        && thirdChild.pages
                                                                                                            .page_url
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        "/" +
                                                                                                                        thirdChild.pages
                                                                                                                            .page_url
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    indexThirdChild
                                                                                                                }
                                                                                                            >
                                                                                                                <a
                                                                                                                    href={
                                                                                                                        thirdChild.menu_customlink
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        thirdChild.menu_name
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    } else {
                                                                        if (firstChild.menu_show_image === 1) {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mega-menu-imgbox">
                                                                                        <a
                                                                                            href={
                                                                                                firstChild.menu_customlink
                                                                                            }
                                                                                        >
                                                                                            {firstChild.menu_desc ? (
                                                                                                <div
                                                                                                    className="mega-menu-imgbox-content"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            firstChild.menu_desc,
                                                                                                    }}
                                                                                                ></div>
                                                                                            ) : null}
                                                                                            <img
                                                                                                src={
                                                                                                    firstChild.menu_image !=
                                                                                                        null
                                                                                                        ? imageUrl +
                                                                                                        firstChild.menu_image
                                                                                                        : constant.DEFAULT_IMAGE
                                                                                                }
                                                                                                width="100%"
                                                                                                height="100%"
                                                                                                alt={firstChild.menu_name}
                                                                                            />

                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                        else {
                                                                            return (
                                                                                <div
                                                                                    className="col"
                                                                                    key={indexFirstChild}
                                                                                >
                                                                                    <div className="mlist">
                                                                                        <h6 className="title"><a href={
                                                                                            firstChild.menu_customlink
                                                                                        }> {firstChild.menu_name}</a></h6>
                                                                                        {firstChild.children.length > 0 ? <>
                                                                                            <ul>
                                                                                                {firstChild.children.map(
                                                                                                    (
                                                                                                        thirdChild,
                                                                                                        indexThirdChild
                                                                                                    ) => {
                                                                                                        if (
                                                                                                            thirdChild.menu_categoryid >
                                                                                                            0 && thirdChild
                                                                                                                .categories
                                                                                                            && thirdChild
                                                                                                                .categories
                                                                                                                .cat_slug
                                                                                                        ) {
                                                                                                            return (
                                                                                                                <li
                                                                                                                    key={
                                                                                                                        indexThirdChild
                                                                                                                    }
                                                                                                                >
                                                                                                                    <a
                                                                                                                        href={
                                                                                                                            "/category/" +
                                                                                                                            thirdChild
                                                                                                                                .categories
                                                                                                                                .cat_slug
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            thirdChild.menu_name
                                                                                                                        }
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                            );
                                                                                                        } else if (
                                                                                                            thirdChild.menu_collectionid >
                                                                                                            0 && thirdChild.collection && thirdChild.collection.cat_slug
                                                                                                        ) {
                                                                                                            return (
                                                                                                                <li
                                                                                                                    key={
                                                                                                                        indexThirdChild
                                                                                                                    }
                                                                                                                >
                                                                                                                    <a

                                                                                                                        href={`/collection/${thirdChild.collection.cat_slug}`}
                                                                                                                    >
                                                                                                                        {
                                                                                                                            thirdChild.menu_name
                                                                                                                        }
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                            );
                                                                                                        }

                                                                                                        else if (
                                                                                                            thirdChild.menu_tagid >
                                                                                                            0 && thirdChild.tags && thirdChild.tags.tag_slug
                                                                                                        ) {
                                                                                                            return (
                                                                                                                <li
                                                                                                                    key={
                                                                                                                        indexThirdChild
                                                                                                                    }
                                                                                                                >
                                                                                                                    <a

                                                                                                                        href={`/tag/${thirdChild.tags.tag_slug}`}
                                                                                                                    >
                                                                                                                        {
                                                                                                                            thirdChild.menu_name
                                                                                                                        }
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                            );
                                                                                                        }

                                                                                                        else if (
                                                                                                            thirdChild.menu_pageid >
                                                                                                            0 && thirdChild.pages
                                                                                                            && thirdChild.pages
                                                                                                                .page_url
                                                                                                        ) {
                                                                                                            return (
                                                                                                                <li
                                                                                                                    key={
                                                                                                                        indexThirdChild
                                                                                                                    }
                                                                                                                >
                                                                                                                    <a
                                                                                                                        href={
                                                                                                                            "/" +
                                                                                                                            thirdChild.pages
                                                                                                                                .page_url
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            thirdChild.menu_name
                                                                                                                        }
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                            );
                                                                                                        } else {
                                                                                                            return (
                                                                                                                <li
                                                                                                                    key={
                                                                                                                        indexThirdChild
                                                                                                                    }
                                                                                                                >
                                                                                                                    <a
                                                                                                                        href={
                                                                                                                            thirdChild.menu_customlink
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            thirdChild.menu_name
                                                                                                                        }
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                            );
                                                                                                        }
                                                                                                    }
                                                                                                )}
                                                                                            </ul>




                                                                                        </> : ''}


                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }
                                                                }
                                                            )}
                                                        </div>
                                                    </div>

                                                </div>
                                            </li>
                                        );
                                    }
                                }


                                else {
                                    if (parent.menu_categoryid > 0 && parent.categories && parent.categories.cat_slug) {
                                        return (
                                            <li
                                                className="nav-item dropdown mega-dropdown dropdown-hover"
                                                key={indexParent}
                                            >
                                                <a
                                                    className="nav-link"
                                                    href={
                                                        "/category/" +
                                                        parent.categories.cat_slug
                                                    }
                                                    id="navbarDropdown"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <span>{parent.menu_name}</span>
                                                </a>

                                            </li>
                                        );
                                    }
                                    else if (parent.menu_collectionid > 0 && parent.collection && parent.collection.cat_slug) {
                                        return (
                                            <li
                                                className="nav-item dropdown mega-dropdown dropdown-hover"
                                                key={indexParent}
                                            >
                                                <a
                                                    className="nav-link "

                                                    href={`/collection/${parent.collection.cat_slug}`}
                                                    id="navbarDropdown"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <span>{parent.menu_name}</span>
                                                </a>

                                            </li>
                                        );
                                    }


                                    else if (parent.menu_tagid > 0 && parent.tags && parent.tags.tag_slug) {
                                        return (
                                            <li
                                                className="nav-item dropdown mega-dropdown dropdown-hover"
                                                key={indexParent}
                                            >
                                                <a
                                                    className="nav-link "

                                                    href={`/tag/${parent.tags.tag_slug}`}
                                                    id="navbarDropdown"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <span>{parent.menu_name}</span>
                                                </a>

                                            </li>
                                        );
                                    }

                                    else if (parent.menu_pageid > 0 && parent?.pages && parent?.pages?.page_url) {
                                        return (
                                            <li
                                                className="nav-item dropdown mega-dropdown dropdown-hover"
                                                key={indexParent}
                                            >
                                                <a
                                                    className="nav-link "
                                                    href={"/" + parent?.pages?.page_url}
                                                    id="navbarDropdown"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <span>{parent.menu_name}</span>
                                                </a>

                                            </li>
                                        );
                                    }
                                    else {
                                        return (
                                            <li
                                                className="nav-item dropdown mega-dropdown dropdown-hover"
                                                key={indexParent}
                                            >
                                                <a
                                                    className="nav-link"
                                                    href={parent.menu_customlink}
                                                    id="navbarDropdown"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <span>{parent.menu_name}</span>
                                                </a>

                                            </li>
                                        );
                                    }
                                }
                            }
                        })
                        : null}</>}

                </ul>
            </div>
        </nav>

    </>)
}


export default Menu