const ProductBox = ({productValue, home_product_style}) => {
  
//product text-center
    return (<>
        <div className={home_product_style && home_product_style == 1? "product text-center product-style-1" : "product text-center product-style-2"}>
            <div className="product-media-shap">
                <figure className="product-media">
                <a href={"/product/" + productValue.product_slug}>
            {productValue?.arrayImages && productValue?.arrayImages.length >= 2 ?
              <>
                <img src={productValue?.arrayImages?.[0]} alt={productValue.product_name} />
                <img src={productValue?.arrayImages?.[1]} alt={productValue.product_name} />
              </>
              :
              <>
                <img src={productValue?.arrayImages?.[0]} alt={productValue.product_name} />
                <img src={productValue?.arrayImages?.[0]} alt={productValue.product_name} />
              </>
            }
          </a>
                </figure>
              
            </div>
            <div className="product-details">
                <h2 className="product-name">
                <a href={"/product/" + productValue.product_slug}>{productValue.product_name}</a>
                </h2>
                <div className="product-price">
                <ins className="new-price">₹ {productValue.product_selling_price}</ins>
                {Number(productValue.product_price) > Number(productValue.product_selling_price) && (<del className="old-price">₹ {productValue.product_price}</del>)}
                </div>
            </div>
        </div>
    </>)
}

export default ProductBox