
import Header from '../../Components/Header/index'
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Footer from '../../Components/Footer/index'
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import Accountsidebar from './account_sidebar';
import { useContext, useState, useEffect, useRef } from 'react';
import DataContext from '../../Components/Elements/context';
import { ApiService } from '../../Components/Services/apiservices';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';






const Address=()=>{
    const contextValues = useContext(DataContext)
    const [isLoading, setisLoading] = useState(false)
    const [userAddressDetails, setUserAddressDetails] = useState({
        ua_id: 0,
        ua_fname: "",
        ua_lname: "",
        ua_email: "",
        ua_mobile: "",
        ua_pincode: "",
        ua_state_name: "",
        ua_city_name: "",
        ua_apartment: "",
        ua_default_address: "",
        ua_complete_address: '',
        ua_country_id: '',
        ua_address_type: "Home",
        ua_address_type_other: "",
    });
    const didMountRef = useRef(true);
    const navigate = useNavigate()
    const [userAddressList, setuserAddressList] = useState([])
    useEffect(() => {
        if (didMountRef.current) {
            getuserAddress()
        }
        didMountRef.current = false;
    }, []);
    const getuserAddress = () => {
        setisLoading(true)
        ApiService.fetchData('get-user-address').then((res) => {
            if (res.status == 'success') {
                setuserAddressList(res.resUserAddress)
                setisLoading(false)
            }
            else if (res.status == 'error' && res.message == 'Session expired') {
                setisLoading(false)
                localStorage.removeItem('USER_TOKEN')
                navigate('/')
            }
        }).catch(() => {
            setisLoading(false)
        })

    }

    const deleteaddress = (value) => {
        if (window.confirm("Are you sure about delete the address ?")) {
            const dataString = {
                addrid: value,
            };
            ApiService.postData("removeAddress", dataString).then((res) => {
                if (res.status == "success") {
                    getuserAddress();
                }
                else {
                    toast.error(res?.message)
                }
            });
        } else {

        }
    };
    const navigateback = (e) => {
        e.preventDefault()

        if (window.history.length > 2) {
            navigate(-1);
        } else {
            navigate('/');
        }
    };
    return(<>
<section className="section-gap-medium">
  <div className="container-fluid">
    <div className="row">
      <div className="col-lg-3">
        <Accountsidebar></Accountsidebar>
      </div>
      <div className="col-lg-9">
        <div className="section-title mb-30">
          <h2>Default Addressess</h2>
        </div>
        <div>
            
        </div>
   

      </div>
    </div>
  </div>
</section>
<Footer></Footer>
   
    
    
    </>)
}

export default Address