
import Header from '../../Components/Header/index'
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Footer from '../../Components/Footer/index'
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import Accountsidebar from './account_sidebar';
import { ApiService } from '../../Components/Services/apiservices';
import { useState, useContext } from 'react';
import DataContext from '../../Components/Elements/context';
import Alert from 'react-bootstrap/Alert';



const Profile = () => {
    const { slug } = useParams()

    console.log(slug)
    const contextValues = useContext(DataContext)
    const [buttonLoader, setButtonLoader] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [resetPassword, setresetPassword] = useState({
        new_password: "",
        confirm_password: ""
    })
    const [step, setStep] = useState(1)
    const navigate = useNavigate()
    const resetPasswordProcess = () => {
        let counter = 0;
        const myElements = document.getElementsByClassName("passwordRequired");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === '') {
                myElements[i].style.border = '1px solid red';
                counter++;
            } else {
                myElements[i].style.border = '';
            }
        }
        if (counter == 0) {
            if (resetPassword.confirm_password.trim() !== resetPassword.new_password.trim()) {
                setErrorMessage('Password and confirm password should be same');
                return false;
            }
            const dataString = {
                user_password: resetPassword.new_password,
                user_confirm_password: resetPassword.confirm_password,

            }
            setButtonLoader(true)
            ApiService.postData("loggedinuserchangepassword", dataString).then((res) => {
                if (res.status == "success") {
                    setSuccessMessage(res.message);
                    setButtonLoader(false)
                    setTimeout(() => {
                        setErrorMessage('')
                    }, 2500)
                } else if (res.status == 'error' && res.message == 'Session expired') {
                    localStorage.removeItem('USER_TOKEN')
                    navigate('/')
                } else {
                    setButtonLoader(false)
                    setErrorMessage(res.message);
                }
            })
        }
    }
    const handleForgetPasswordChange = (e) => {
        const { name, value } = e.target;
        setresetPassword(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
        if (value !== '') {
            e.target.style.border = '';
        }
    };
    const handleProfileChange = (e) => {
        const { name, value } = e.target;
        contextValues.setRowUserData(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
        if (value !== '') {
            e.target.style.border = '';
        }
    };
    const handleProfileUpdate = () => {
        let counter = 0;
        const myElements = document.getElementsByClassName("profileRequired");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === '') {
                myElements[i].style.border = '1px solid red';
                counter++;
            } else {
                myElements[i].style.border = '';
            }
        }
        if (counter == 0) {
           
            const dataString = {
                user_fname: contextValues.rowUserData?.user_fname,
                user_lname: contextValues.rowUserData?.user_lname,
            }
            setButtonLoader(true)
            ApiService.postData("update-user-data", dataString).then((res) => {
                if (res.status == "success") {
                    setSuccessMessage(res.message);
                    setButtonLoader(false)
                    window.location.reload()
                    setTimeout(() => {
                        setErrorMessage('')
                    }, 2500)
                } else if (res.status == 'error' && res.message == 'Session expired') {
                    localStorage.removeItem('USER_TOKEN')
                    navigate('/')
                } else {
                    setButtonLoader(false)
                    setErrorMessage(res.message);
                }
            })
        }
    }

    return (<>

        <Header></Header>
        <section className="section-gap-medium">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-3">
                        <Accountsidebar></Accountsidebar>
                    </div>
                    <div className="col-lg-9">
                        <div className="section-title mb-30">
                            <h2>Account Information</h2>
                        </div>
                        {errorMessage ? <Alert key={'danger'} className='danger' variant='danger'>{errorMessage}</Alert> : ''}
                        {successMessage ? <Alert key={'success'} className='success' variant='success'>{successMessage}</Alert> : ''}
                        <div className='row'>
                            <div className='col-6'>
                                <div className=" mb-20">
                                    <div class="form-floating mb-3">
                                        <input type="text" className="form-control " placeholder="First Name" name='user_fname' value={contextValues.rowUserData?.user_fname} onChange={(e)=>{handleProfileChange(e)}}/>
                                        <label for="floatingPassword">First Name</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" className="form-control " placeholder="Last Name" name='user_lname' value={contextValues.rowUserData?.user_lname} onChange={(e)=>{handleProfileChange(e)}}/>
                                        <label for="floatingPassword">Last Name</label>
                                    </div>
                                    <button type="button" className="btn btn-primary btn-full btn-lg" onClick={()=>{handleProfileUpdate()}}>Update</button>

                                </div>
                            </div>
                            {slug == 'change-password' && (
                                <div className='col-6'>
                                    <div className=" mb-20">
                                        <div class="form-floating mb-3">
                                            <input type="password" className="form-control passwordRequired" placeholder="Password" name='new_password' onChange={(e) => { handleForgetPasswordChange(e) }} />
                                            <label for="floatingPassword">Password</label>
                                        </div>
                                        <div class="form-floating mb-3">
                                            <input type="password" className="form-control passwordRequired" placeholder="Confirm Password" name='confirm_password' onChange={(e) => { handleForgetPasswordChange(e) }} />
                                            <label for="floatingPassword">Confirm Password</label>
                                        </div>
                                        <button type="button" className="btn btn-primary btn-full btn-lg" onClick={() => { resetPasswordProcess() }}>Change Password</button>

                                    </div>
                                </div>
                            )}


                        </div>

                        <div>

                        </div>



                    </div>
                </div>
            </div>

        </section>
        <Footer></Footer>



    </>)
}

export default Profile