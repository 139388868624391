
import Header from '../../Components/Header/index'
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Footer from '../../Components/Footer/index'
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { ApiService } from '../../Components/Services/apiservices';
import Accountsidebar from './account_sidebar';
import { useContext } from 'react';
import DataContext from '../../Components/Elements/context';
import { useNavigate } from 'react-router-dom';





const Account = () => {
  const navigate = useNavigate()
  const contextValues = useContext(DataContext)

  const logoutProcess = () => {
    const confirmed = window.confirm("Are you sure ? Want to log out");
    if (confirmed) {
      ApiService.fetchData("logout").then((res) => {
        if (res.status === "success") {
          const user_Token = localStorage.getItem('USER_TOKEN')
          const user_Trade_Token = localStorage.getItem('USER_TRADE_TOKEN')
          if (user_Token && user_Token !== null) {
            localStorage.removeItem("USER_TOKEN")
          }
          else if (user_Trade_Token) {
            localStorage.removeItem("USER_TRADE_TOKEN")
          }

          window.location.href = '/'
        }
      })
    }
  }
  const ChangePassword = () => {
    navigate('/profile/change-password')
  }
  return (<>

    <Header></Header>
    <section className="section-gap-medium">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3">
            <Accountsidebar></Accountsidebar>
          </div>
          <div className="col-lg-9">
            <div className="section-title mb-30">
              <h2>Account Information</h2>
            </div>
            <h5>Contact Information</h5>
            <div>
              <p>{contextValues.rowUserData.user_fname}</p>
              <p>{contextValues.rowUserData.user_email}</p>
            </div>
            <div className=''>
              <a href='/profile'><p>Edit</p></a>
              <p onClick={() => { ChangePassword() }}>Change Password</p>
            </div>
            <h5>Address Book <span onClick={() => { navigate('/address') }}>Manage Addresses</span></h5>
            <h6>Default Billing Address</h6>
            {contextValues.UserDefaultAddress && contextValues.UserDefaultAddress.length > 0 ? (
              <>
                <div>
                  <p></p>
                </div>
                <div className=''>
                  <a href={`/address/${contextValues.UserDefaultAddress.ua_id}`}><p>Edit</p></a>
                </div>
              </>


            ) : (<div>
              <p>You have not set a default billing address. </p>
            </div>)}

          </div>
        </div>
      </div>
      
    </section>
    <Footer></Footer>
  </>)
}

export default Account