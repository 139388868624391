

const Checkout=()=>{
    return(<>
    <div className="container">
        <div className="row">
            <div className="col-6">
            
            </div>
        </div>
    </div>
   
    </>)
}

export default Checkout