
import Header from '../../Components/Header/index'
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Footer from '../../Components/Footer/index'
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
// import { addToCart, addToCartSession } from "../../Component/Element/add_to_cart";
import { ApiService } from '../../Components/Services/apiservices';
import constant from '../../Components/Services/constant';
import DataContext from '../../Components/Elements/context';
import { addToCartSession, addToCart } from '../../Components/Elements/add_to_cart';
import Help from './help';




const Wishlist = () => {
    const didMountRef = useRef(true)
    const contextValues = useContext(DataContext)
    const navigate = useNavigate()
    const [listData, setListData] = useState([])
    const [isLoading, setisLoading] = useState(false)
    const [spinnerloading, setspinnerloading] = useState('')
    const [ImgUrl, setImgurl] = useState('')
    const sessionToken = localStorage.getItem('USER_TOKEN')
    useEffect(() => {
        if (didMountRef.current) {
            getWishlistProduct()
        }
        didMountRef.current = false;
    }, []);
    const getWishlistProduct = () => {
        setisLoading(true)
        ApiService.fetchData('/user-fav-data').then((res) => {
            if (res.status == 'success') {
                setListData(res.favdata)
                setImgurl(res.img_path)
                setisLoading(false)
            }
            else if (res.status == 'error' && res.message == 'Session expired') {
                toast.error(res.message);
                localStorage.removeItem('USER_TOKEN')
                window.location.href = '/'
                setisLoading(false)
            }
            else {
                setisLoading(false)
            }
        }).catch(() => {
            setisLoading(false)
        })
    }
    const discount = (value) => {

        var discountPrice = (value.product_price - value.product_selling_price) / value.product_price
        discountPrice = Math.round(discountPrice * 100)
        return discountPrice
    }
    
    const Removeaddtofav = (addproduct) => {
        const dataString = {
            upf_variation_id:addproduct?.upf_variation_id,
            product_id:addproduct?.product?.product_id
        };
        ApiService.postData("remove-fav-wishlist", dataString).then((res) => {
            if (res.data.status == "success") {
                getWishlistProduct()
            }
        }).catch(() => {
        });
    };
  
    const addToCartProcess = async (addproduct) => {

        if (parseFloat(addproduct?.product?.product_selling_price) > 0) {
            const productData = {
                product_id: Number(addproduct?.product?.product_id),
                product_name: addproduct?.product?.product_name,
                product_slug: addproduct?.product?.product_slug,
                product_image: addproduct?.product?.product_image,
                product_type: Number(addproduct?.product?.product_type),
                product_price: parseFloat(addproduct?.product?.product_price).toFixed(2),
                product_selling_price: parseFloat(addproduct?.product?.product_selling_price).toFixed(2),
                product_discount: addproduct?.product?.product_discount,
                product_variation: addproduct.variationdata,
                product_category_id: addproduct?.product?.product_category_id,
                selected_variation: addproduct.ufp_selected_variation,
                quantity: addproduct.quantity,
                product_variation_id: addproduct?.upf_variation_id ? addproduct?.upf_variation_id : null,

            };
            contextValues.setSpinnerCubLoader(addproduct.product_id)
            if (localStorage.getItem("USER_TOKEN")) {
                const updateStatus = await addToCartSession(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                    Removeaddtofav(addproduct)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await addToCart(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                    Removeaddtofav(addproduct)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };
    return (<>

        <Header></Header>
        <div className="subheader ">
            <Container>
                <Row>
                    <Col lg={12}>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="/wishlist">Wishlist</Breadcrumb.Item>

                        </Breadcrumb>
                    </Col>
                </Row>
            </Container>

        </div>
        <section className="section-gap-medium">
            <div className="container">
                <div className="row">

                    <div className="col-lg-12">
                        {/* <div className="section-title">
                            <h2>My Favourites</h2>
                        </div> */}
                        {listData && listData.length > 0 ?
                            <div className="row g-3">
                                {listData?.map((item, index) => {
                                    return (<>
                                        <div className='col-4 mb-5' key={index}>
                                            <div className={'product wishlist product-style-1'}>
                                                <div className="product-media-shap">

                                                    <figure className="product-media">
                                                        <div className='wish-remove' onClick={() => { Removeaddtofav(item)}}>
                                                            <i class="ri-heart-3-fill"></i>
                                                        </div>
                                                        <a href={"/product/" + item.product.product_slug}>
                                                            <img src={item?.product?.product_image} alt={item?.product?.product_name} />
                                                        </a>
                                                       
                                                    </figure>

                                                </div>
                                                <div className="product-details">
                                                    <div className='sub-details'>
                                                        <h2 className="product-name">
                                                            <a href={"/product/" + item?.product?.product_slug}>{item?.product?.product_name}</a>
                                                        </h2>
                                                        <div className="product-price">
                                                            <ins className="new-price">₹ {item?.product?.product_selling_price}</ins>
                                                            {Number(item?.product?.product_price) > Number(item?.product?.product_selling_price) && (<del className="old-price d-none">₹ {item?.product?.product_price}</del>)}
                                                            {discount(item?.product) > 0 && <span className="tx-12 tx-red d-none">{discount(item?.product)}% Off</span>}
                                                        </div>
                                                    </div>
                                                    <ul className='d-none'>
                                                        {item.product.product_type == 1 && item.ufp_selected_variation && item.ufp_selected_variation.length > 0 ?
                                                            <>
                                                                {item.ufp_selected_variation.map((value, index) => {
                                                                    return (<li key={index}>{value.attr}: {value.terms}</li>)
                                                                })}
                                                            </>
                                                            : null}

                                                    </ul>
                                                    <button className="wish-addtocart mt-3" onClick={() => { addToCartProcess(item) }}>Add To Cart</button>
                                                </div>
                                            </div>

                                        </div>

                                    </>)
                                })}


                            </div> :
                            !sessionToken && sessionToken==null ? 
                            <div className='noimg text-center'>
                              
                                <h2 className='mb-3'>My Wishlist
                               </h2>
                                <p className='tx-gray tx-14 mb-3'> You want to have all your favorite products with you?</p>
                                <a href='/register' className='btn btn-primary w-lg btn-lg mb-3'><span>Register</span></a>
                                <p class="text-center">Already have an account? <a href="/login" class="tx-primary">Login</a></p>
                            </div>:
                            <div className='noimg text-center'>
                            <img src='/img/love.png' className='mb-20' style={{ width: "50px" }}></img>
                            <h2>See it, love it, save it!</h2>
                            <p className='tx-gray tx-14'>Keep track of your favourite items and inspiration by selecting the heart icon</p>
                            <a href='/' className='btn btn-primary w-lg btn-lg mt-3'><span>Continue Shopping</span></a>
                        </div>
                        }

                    </div>
                </div>
            </div>
        </section>
        <Help />
        <Footer></Footer>



    </>)
}

export default Wishlist