import { useContext, useState } from "react"
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import { ApiService } from "../Services/apiservices";
import DataContext from "../Elements/context";
import constant from "../Services/constant";
import { validEmail } from "../Elements/Regex";
const SignUpModal = () => {
  const contextValues = useContext(DataContext)
  const signupModal = () => {
    contextValues.setToggleSignRukmanModal(!contextValues.toggleSignRukmanModal)
  }
  const atOnClose=()=>{
    localStorage.setItem("MODALOPEN", "FALSE");
    contextValues.setToggleSignRukmanModal(false)
  }
  const [usermail, setUsermail] = useState('')
  const [loader, setloader] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const getSignUp=()=>{
    if(usermail==''){
      setErrorMessage('Please enter the email id' )
      return
    }
    if (!validEmail.test(usermail)) {
      setErrorMessage('Invalid Email Format')
      return false;
  }

  const dataString={
    newsletter_email:usermail
  }
setloader(true)
    ApiService.postData('/subscription-process', dataString).then((res)=>{
      if(res?.status=='success'){
        setSuccessMessage(res.message)
        setTimeout(()=>{
          setSuccessMessage('')
          setloader(false)
          setErrorMessage('')
          setUsermail('')
          atOnClose()
        }, 1000)
      }
      else if(res.status=='error'){
        setErrorMessage(res.message)
        setTimeout(()=>{
          setSuccessMessage('')
          setloader(false)
          setErrorMessage('')
          setUsermail('')
          signupModal()
        }, 1000)
      }
    })
  }
  return (<>
    <Modal show={contextValues.toggleSignRukmanModal}   centered onHide={(e) => signupModal()} className="newsletteModal">
      <div className="newsletteModalContent">
      <button type="button" className="newsletteModal-Close" onClick={() => { atOnClose() }}><i className="ri-close-line ri-xl"></i></button>
        <div className="row g-0 align-items-center">
        <div className="col-lg-6">
            <img src='/img/letterimg1.jpg' style={{width:'100%'}}></img>
          </div>
          <div className="col-lg-6">
            <div className="newsletteModalC">
              <div className="tx-center mb-30">
              <h5>LEARN HOW TO UNLOCK </h5>
              <h2>25% Off Site-wide</h2>
              </div>
                
          {errorMessage ? <Alert key={'danger'} className='danger' variant='danger'>{errorMessage}</Alert> : ''}
          {successMessage ? <Alert key={'success'} className='success' variant='success'>{successMessage}</Alert> : ''}
            <div className="form-group mb-15">
              <input type="text" placeholder="Email" name="user_mail" value={usermail} className='userRegisterRequired' onChange={(e) => { setUsermail(e.target.value) }} />
            </div>
         <button class="btn btn-lg btn-primary btn-full mb-20" onClick={()=>{getSignUp()}} disabled={loader}>{loader ? (<img src="/img/loder01.gif" width="60px" height="11px" />) :'SIGN UP'}</button>
         <p className="tx-12 tx-center">*By entering, you agree to join our mailing list. We will send you the latest news, updates and promotions. You can unsubscribe at any time.</p>
         </div>
          </div>
         
        </div>
        </div>
    </Modal>
  </>)
}

export default SignUpModal