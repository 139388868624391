import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Accordion from 'react-bootstrap/Accordion';
import { BrowserView, MobileView } from "react-device-detect"
import React, { useContext, useEffect, useRef, useState, useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../Components/Services/apiservices";


const Faq = () => {
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    const [faqData, setfaqData] = useState([])
    const [faqcatwiseData, setfaqcatwiseData] = useState([])
    const [faqimgUrl, setfaqimgUrl] = useState('')
    const [faqcatName, setfaqCatName] = useState('')
    const [addActive, setaddActive] = useState('')
    const [isLoading, setisLoading] = useState(false)
    
    useEffect(() => {
        if (didMountRef.current) {
            getfaqData()

        }
        didMountRef.current = false;
    }, []);
    const getfaqData = () => {
        setisLoading(true)
        ApiService.fetchData('categoryFaqs').then((res) => {
            if (res.status == 'success') {
                setfaqData(res.faqCatData)
                setfaqCatName(res?.faqCatData?.[0].fc_name)
                setfaqimgUrl(res.category_image_path)
                setisLoading(false)
                setfaqcatwiseData(res.faqCatData[0].faqs)
                setaddActive(res.faqCatData[0].fc_id)
            }
            else {
                setisLoading(false)
            }
        }).catch(() => {
            setisLoading(false)
        })
    }


    const getcatwisefaq = (catId) => {
        setaddActive(catId)
        const filteredFaqs = faqData.filter(faq => faq.fc_id === catId);
        setfaqCatName(filteredFaqs[0].fc_name)
        setfaqcatwiseData(filteredFaqs[0].faqs);

    }


    return (<>
        <Header></Header>
        <div className="faqsection">
            <div className="faqsection-left">
                
                {faqData && faqData.length>0 && <div className="faqlist">
                        <ul>
                            {faqData.map((item , index)=>{
                                return(<>
                                <li key={index} onClick={() => { getcatwisefaq(item.fc_id) }}>{item.fc_name}</li>
                                </>)
                            })}
                            
                        </ul>
                        </div>
                     }
                
            </div>
            <div className="faqsection-right">
            <div className="section-title mb-20">
                                    <h3>{faqcatName}</h3>
                                </div>
                                {faqcatwiseData && faqcatwiseData.length > 0 ? <>
                                    <div className="faqaccordation">
                                        <Accordion >
                                            {faqcatwiseData?.map((items, index) => {
                                                return (<>
                                                    <Accordion.Item eventKey={index}>
                                                        <Accordion.Header>{items.faq_title}</Accordion.Header>
                                                        <Accordion.Body dangerouslySetInnerHTML={{ __html: items.faq_description }}>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </>)
                                            })}
                                        </Accordion>
                                    </div>

                                </> : <div className="faqaccordation">
                                    <p >No FAQ Found for this category</p>
                                </div>}
            </div>
        </div>
     
        <Footer></Footer>

    </>)
}

export default Faq