import React, { useEffect, useState, useRef } from "react"; 
import { BrowserView, MobileView } from "react-device-detect";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";


function PageNotFound() {
  return (
    <>
  
      <BrowserView>
      <Header innerHeader={'innerHeader'}/>
         <section className="sec-gap-medium text-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-12" >
                <h1 className="mt-5">Oops, you found our 404 page</h1>
                <img src="/img/img1.gif" alt="404 Error" style={{width : "500px"}} />
                <h5 className="mb-5">The page you requested does not exist. <a href="/">Click here</a> to continue shopping.</h5>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>
       </BrowserView>

      <MobileView>
      <Header innerHeader={'innerHeader'}/>
        <section className="sec-gap-medium text-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-12" >
              <h1 className="mt-5">Oops, you found our 404 page</h1>
                <img src="/img/img1.gif" alt="404 Error" style={{width : "300px"}} />
                <h5 className="mb-5">The page you requested does not exist. <a href="/">Click here</a> to continue shopping.</h5>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </MobileView>
    
    </>
  );
}
export default PageNotFound;