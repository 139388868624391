import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import MobileHeader from "../../Components/Header/mobile_header"

const AboutUs=()=>{
    return(<>
    
    <Header></Header>
    <section className="section-gap-medium text-center">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-9">
                    <div className="section-title">
                    <span className="pre-title text-uppercase">Our Purpose</span>
                    <h1>Inspire a more creative way of living through good design that’s good to everyone.</h1>
                </div>
                </div>
            </div>
        </div>
    </section>
    <section className="section-gap-medium">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                <div className="section-title">
                    <h2>Our theory is simple.</h2>
                </div>
                </div>
                <div className="col-lg-6">
                    <p>We believe design should do more than just look good. It needs to consider how each of us live and work, finding creative ways to make both easier and more delightful. To that end, we thoughtfully design every piece to have personality and a purpose. Namely, to be enjoyed by everyone for a good long time.</p>
                </div>
            </div>
        </div>
    </section>
    <section className="section-gap-medium pt-0">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                <img src="https://www.bludot.com/media/wysiwyg/about-us/1440_image1.jpg" className="img-fluid"></img>
                </div>
          
            </div>
        </div>
    </section>
    <section className="section-gap-medium">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className="section-title mb-20">
                        <h2>We design everything we make.</h2>
                    </div>
                    <p>We are designers who work together to make good design happen. Inside our Minneapolis shop, you’ll find us elbow deep in sketches or playing show-and-tell with concepts. Our design process is a total collaboration from pencil and paper, materials and machines, even packaging and assembly.</p>
                    <a href="#" class="linebutton linebutton-small mt-20 mb-20">Check out our process</a>
                    <img src="https://www.bludot.com/media/wysiwyg/about-us/1440_image4.jpg" className="img-fluid"></img>
                </div>
                <div className="col-lg-6">
                    <div className="about-img">
                        <img className="about-img-first" src="https://www.bludot.com/media/wysiwyg/about-us/1440_image2.jpg"></img>
                        <img className="about-img-second" src="https://www.bludot.com/media/wysiwyg/about-us/1440_image3.jpg"></img>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="section-gap-medium">
        <div className="container">
            <div className="row align-items-center">
            <div className="col-lg-6">
            <div className="about-img-second">
                <img src="https://www.bludot.com/media/wysiwyg/about-us/1440_Aboutus_image5.jpg" style={{width:'348px'}}></img>
                <img src="https://www.bludot.com/media/wysiwyg/about-us/1440_Aboutus_image6.jpg" style={{width:'462px'}}></img>
            </div>
            </div>
                <div className="col-lg-6">
                    <div className="section-title mb-20">
                        <h2>Design that does better.</h2>
                    </div>
                    <p>Good design is only truly good when it’s good to everyone. As we lay out a path for our sustainability work, we’ve looked at everything we do through that lens. From the durability of our designs to the materials we use, the spaces we’re in and the company we keep—we’ve scrutinized how we operate in every area. And we’ve analyzed what we can do differently to minimize our environmental impact while continuing to create purposeful and inspiring designs we all feel great about.</p>
                    <p>We’re focused on a variety of goals that fall within four key areas: our design principles, choosing the right materials, maintaining high standards and our internal commitments. Accomplishing each of these goals will take time and require an ongoing effort, but we’re in it for the long haul. We believe we owe it to our communities, our partners and the world around us. See our full plan. </p>
                    
                    <img src="https://www.bludot.com/media/wysiwyg/about-us/1440_Aboutus_image7.jpg" className="img-fluid mt-20"></img>
                </div>
                
            </div>
        </div>
    </section>
    <section className="section-gap-medium">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="section-title text-center">
                        <h2>Our people are our superpower.</h2>
                        <p>You won’t find any designers in capes here. Everything we design is a collaboration of a whole slew of people throughout Blu Dot. Each of us playing an equally important role to make good design happen, while doing what we love. (Win-win!) What makes this group effort work? Strong culture and talented people, most of whom have been with us for many years, and some since the very beginning. Want to be one of them?</p>
                        <a href="#" class="linebutton linebutton-small mt-20">See career opportunities</a>

                    </div>
                </div>
            </div>
        </div>
        </section>
        <section className="section-gap-medium pt-0">
        <div className="container-fluid px-0">
            <div className="row g-0 justify-content-center">
                <div className="col-lg-12">
                 <img className="img-fluid" src="https://www.bludot.com/media/wysiwyg/about-us/1440_ourpeople_temp.jpg"/>
                </div>
            </div>
        </div>
        </section>
    <Footer></Footer>
    </>)
}

export default AboutUs