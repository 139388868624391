import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper/modules";
import { Navigation, Pagination, Scrollbar, A11y, EffectFade } from 'swiper/modules';
import React, { useEffect, useRef, useState, useCallback } from "react";
import { ApiService } from '../../../Components/Services/apiservices';
import constant from '../../../Components/Services/constant';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';


const HomeSlider = () => {
  const navigate = useNavigate()
  const [sliderData, setSliderData] = useState([])
  const [mobilesliderData, setMobileSliderData] = useState([])
  const [isLoading, setisLoading] = useState(true);
  const didMountRef = useRef(true);
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  useEffect(() => {
    if (didMountRef.current) {

      getSliderData()
    }
    didMountRef.current = false
  })

  const getSliderData = () => {
    ApiService.fetchData("top-banner-list").then((res) => {
      if (res.status == "success") {
        setSliderData(res.resTopBannerData)
        setMobileSliderData(res.resMobileBannerData)
        setTimeout(() => {
          setisLoading(false)
        }, 500)
      } else {
        setTimeout(() => {
          setisLoading(false)
        }, 500)
      }
    }).catch(() => {
      setTimeout(() => {
        setisLoading(false)
      }, 500)
    })
  }

  const onBannerClick = (e, value) => {
    e.preventDefault()
    if (value.cat_slug !== null && value.slider_category !== null) {
      navigate(`/collections/category/${value?.category?.cat_slug}`)
    }
    else if (value.tag_slug !== null && value.slider_tags !== null) {
      navigate(`/collections/tag/${value?.tag?.tag_slug}`)
    }
    else if (value.slider_url !== null) {
      navigate(value.slider_url)
    }

  }


  return (<>
    <BrowserView>
      {isLoading ? <>
        <div className='homeslider'>
          <div className='homesliderMedia'  >
            <a href={"javascript:void(0)"} >
              <Skeleton width={"100%"} height={'850px'}></Skeleton>
            </a>
          </div>

        </div>
      </> : <>
        {sliderData && sliderData.length > 0 ? <>
          <div className='homeslider'>
            <Swiper
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Navigation, EffectFade, Pagination, Scrollbar, A11y, Autoplay]}
              ref={sliderRef}
            >
              {sliderData.map((slide, index) => (
                <SwiperSlide key={index}>
                  {slide.slider_view === 2 ? (
                    <>
                      <div className="homesliderMedia" onClick={(e) => { onBannerClick(e, slide) }}>
                        <video src={slide.slider_video} autoPlay="autoplay" loop muted playsInline ></video>
                      </div>
                      {slide.slider_desc && (
                        <div className='homesliderContent'>
                          <div dangerouslySetInnerHTML={{ __html: slide.slider_desc }}></div>
                        </div>
                      )}

                    </>

                  ) : (

                    <>
                      <div className='homesliderMedia' onClick={(e) => { onBannerClick(e, slide) }} >
                        <img src={slide.slider_image} alt="slider_image" />
                      </div>
                      {slide.slider_desc && (
                        <div className='homesliderContent'>
                          <div dangerouslySetInnerHTML={{ __html: slide.slider_desc }}></div>
                        </div>
                      )}
                    </>
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
            {/* <div className='swiper-arrow'>
              <div className="prev-arrow" onClick={handlePrev}><i class="d-icon-arrow-left"></i></div>
              <div className="next-arrow" onClick={handleNext}><i class="d-icon-arrow-right"></i></div>
            </div> */}
          </div>
        </> : null}
      </>}


    </BrowserView>
    <MobileView>
      {isLoading ? <>
        <div className='homeslider'>
          <div className='homesliderMedia'  >
            <a href={"javascript:void(0)"} >
              <Skeleton width={"100%"} height={'850px'}></Skeleton>
            </a>
          </div>

        </div>
      </> : <>
        {mobilesliderData && mobilesliderData.length > 0 ? <>
          <div className='homeslider'>
            <Swiper
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Navigation, EffectFade, Pagination, Scrollbar, A11y, Autoplay]}
              ref={sliderRef}
            >
              {mobilesliderData.map((slide, index) => (
                <SwiperSlide key={index}>
                  {slide.slider_view === 2 ? (

                    <>

                      <div className="homesliderMedia" onClick={(e) => { onBannerClick(e, slide) }}>
                        <video src={slide.slider_video} autoPlay="autoplay" loop muted playsInline ></video>
                      </div>
                      {slide.slider_desc && (
                       <div className='homesliderContent'>
                       <div dangerouslySetInnerHTML={{ __html: slide.slider_desc }}></div>
                     </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className='homesliderMedia' onClick={(e) => { onBannerClick(e, slide) }} >
                        <img src={slide.slider_image} alt="slider_image" />
                      </div>
                      {slide.slider_desc && (
                       <div className='homesliderContent'>
                       <div dangerouslySetInnerHTML={{ __html: slide.slider_desc }}></div>
                     </div>
                      )}
                    </>
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
            <div className='swiper-arrow'>
              <div className="prev-arrow" onClick={handlePrev}><i class="d-icon-arrow-left"></i></div>
              <div className="next-arrow" onClick={handleNext}><i class="d-icon-arrow-right"></i></div>
            </div>
          </div>
        </> : null}
      </>}
    </MobileView>

  </>)
}

export default HomeSlider