import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import React, { useContext, useState, useEffect, useRef } from 'react';
import { ApiService } from "../../Components/Services/apiservices";
import { validEmail, validNumber } from "../../Components/Elements/Regex";
import Alert from 'react-bootstrap/Alert';


const Register=()=>{
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [buttonLoader, setButtonLoader] = useState(false);
    const [step, setStep] = useState(1);
    const [userRegDetails, setUserRegDetails] = useState({
        user_fname: '',
        user_lname: '',
        user_email: "",
        user_mobile: "",
        user_pass: '',
        user_type:''

    });
    const onTodoRegChange = (e) => {
        setErrorMessage('')
        const { name, value } = e.target;
        setUserRegDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value !== '') {
            e.target.style.border = '';
        }
    }
    const registerProcess = (e) => {

        let counter = 0;
        const myElements = document.getElementsByClassName("userRegisterRequired");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === '') {
                myElements[i].style.border = '1px solid red';
                counter++;
            } else {
                myElements[i].style.border = '';
            }
        }
        if (counter === 0) {
            if (!validEmail.test(userRegDetails.user_email)) {
                setErrorMessage('Invalid Email Format')
                return false;
            }

            if (!validNumber.test(userRegDetails.user_mobile)) {
                setErrorMessage("Phone Number must be in 10 Digits");
                return false;

            }
            const dataString = {
                user_fname: userRegDetails.user_fname,
                user_lname: userRegDetails.user_lname,
                user_mobile: userRegDetails.user_mobile,
                user_email: userRegDetails.user_email,
                user_password: userRegDetails.user_pass,
                user_type:1

            };
            setButtonLoader(true)
            ApiService.loginProccessPostData("registerprocess", dataString).then((res) => {
                if (res.status === "success") {
                    setTimeout(() => {
                        setSuccessMessage(res.message)
                        localStorage.setItem("USER_TOKEN", res.user_token)
                        localStorage.removeItem('USER_TRADE_TOKEN')
                        setButtonLoader(false)
                        window.location.href = '/';
                    }, 1000);
                } else if (res.status == 'error' && res.message == 'Session expired') {
                    setErrorMessage(res.message);
                    setTimeout(() => {
                        localStorage.removeItem("USER_TOKEN");
                        setButtonLoader(false)
                    }, 500);
                } else if (res.status == 'error') {
                    setErrorMessage(res.message);
                    setButtonLoader(false)

                }
            }).catch((error) => {
                setErrorMessage('')
                setButtonLoader(false)
            });
        }

    }
    return(<>
     <Header></Header>
     <section className="section-gap-medium">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="section-title text-center mb-40">
                            <h2>Regsiter</h2>
                        </div>
                        <div className="loginbox mb-20">
                        {errorMessage ? <Alert key={'danger'} className='danger' variant='danger'>{errorMessage}</Alert> : ''}
                        {successMessage ? <Alert key={'success'} className='success' variant='success'>{successMessage}</Alert> : ''}
                        <div class="form-floating mb-3">
                                <input type="text" className="form-control userRegisterRequired" id="floatingInput" name="user_fname" value={userRegDetails.user_fname}  onChange={(e) => { onTodoRegChange(e) }} placeholder="first name"/>
                                <label for="floatingInput">First Name</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="text" className="form-control " id="floatingInput" name="user_lname" value={userRegDetails.user_lname}  onChange={(e) => { onTodoRegChange(e) }} placeholder="last name" />
                                <label for="floatingInput">Last Name</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="email" className="form-control userRegisterRequired" id="floatingInput" placeholder="name@example.com" name="user_email" value={userRegDetails.user_email}  onChange={(e) => { onTodoRegChange(e) }}/>
                                <label for="floatingInput">Email address</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="number" className="form-control userRegisterRequired" id="floatingInput"  name="user_mobile" min={0} placeholder="mobile no" value={userRegDetails.user_mobile}  onChange={(e) => { onTodoRegChange(e) }}/>
                                <label for="floatingInput">Mobile</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="password" className="form-control userRegisterRequired" id="floatingPassword" placeholder="Password" name='user_pass' value={userRegDetails.user_pass}  onChange={(e) => { onTodoRegChange(e) }}/>
                                <label for="floatingPassword">Password</label>
                            </div>
                            <button type="button" className="btn btn-primary btn-full btn-lg" disabled={buttonLoader} onClick={() => { registerProcess() }}> {buttonLoader ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : ("Register")}</button>
                        

                        </div>
                        <p className="text-center">Already have an account? <a href="/login" className="tx-primary">Login</a></p>
                    </div>
                </div>
            </div>
        </section>

    <Footer></Footer>
    </>)
}

export default Register