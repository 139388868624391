
import Header from '../../Components/Header/index'
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Footer from '../../Components/Footer/index'
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
// import AccountSidebar from "./account_sidebar";


const Orders=()=>{
    return(<>
  
    <Header></Header>
    <div className="subheader ">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item href="/account">My Account</Breadcrumb.Item>
                                <Breadcrumb.Item active>My Orders</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>

            </div>
            <section className="section-gap-medium">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-lg-3">
                            <AccountSidebar></AccountSidebar>
                        </div> */}
                        <div className="col-lg-9">
                            <div className="section-title">
                                <h2>My Orders</h2>
                            </div>
                            <div className="row g-3">
                              

                            </div>
                        </div>
                    </div>
                </div>
            </section>
    <Footer></Footer>
  

  
    
    </>)
}

export default Orders