const HomeBelowBanner=()=>{
    return(<>
       <section className="section-gap-medium bg-light">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="catSection">
                            <div className="row g-0 align-items-center">
                                <div className="col-lg-6">
                                    <div className="categoriesbox">
                                        <div className="categoriesbox-media">
                                            <img src="/img/images2.jpg"></img>
                                        </div>
                                        <div className="categoriesbox-content">
                                            <h2 className="title mb-20">York Desk Lamp</h2>
                                            <p>Based on an antique we found in a Hampshire market this versatile lamp is exceptionally well engineered. The three swivel joints and top ball joint have a full range of movement to allow for a wide variation of lighting moods.</p>
                                        <a href="#" className="linebutton mt-20">Learn more about this product</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="catSection-benner">
                                        <img src="/img/unsubscribe.jpg" className="img-fluid"></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    
    </>)
}


export default HomeBelowBanner