
import { BrowserView, MobileView } from "react-device-detect"
import React, { useContext, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { ApiService } from "../../Components/Services/apiservices";
import constant from "../../Components/Services/constant";

const BlogDetail = () => {
    const didMountRef = useRef(true)
    const { slug } = useParams()
    const navigate = useNavigate()
    const [blogsData, setblogData] = useState({})
    const [relatedblogsList, setrelatedblogsList] = useState([])
    const [blogscateData, setblogcateData] = useState([])
    const [blogimgUrl, setblogimgUrl] = useState('')
    const [isLoading, setisLoading] = useState(true)
    useEffect(() => {
        if (didMountRef.current) {
            getblogsdetailData()

        }
        didMountRef.current = false;
    }, []);
    const getblogsdetailData = () => {
        setisLoading(true)
        const dataString = {
            blog_slug: slug
        }
        ApiService.postData('/blog-details', dataString).then((res) => {
            if (res.status == 'success') {
                setblogimgUrl(res.blog_image_path)
                setblogcateData(res.categoryData)
                setblogData(res.data)
                setisLoading(false)
                setrelatedblogsList(res.relatedBlogs)
            }
            else {
                setisLoading(false)
            }
        }).catch(() => {
            setisLoading(false)
        })
    }


    return (<>
        <BrowserView>
            <Header innerHeader={'innerHeader'}></Header>
            {isLoading ? <>
                <div >
                    <div className="container-fluid px-0">
                        <div className="row g-0 align-items-center">
                            <div className="col-lg-6">
                                <div >
                                    <h1><Skeleton width={'300px'}></Skeleton></h1>
                                    <ul >

                                        {[...Array(3)].map((item, index) => {
                                            return (<>
                                                <li key={index} ><a href="javascript:void(0)"><Skeleton width={'100px'}></Skeleton></a></li>
                                            </>)
                                        })}

                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <Skeleton width={'700px'} height={'600px'}></Skeleton>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5 text-center" ><Skeleton width={'700px'} height={'300px'}></Skeleton></div>
            </> : <> <div >
                <div className="container-fluid px-0">
                    <div className="row g-0 align-items-center">
                        <div className="col-lg-6">
                            <div >
                                <h1>{blogsData.blog_name}</h1>

                                {blogsData.blog_category_name && blogsData?.blog_category_name?.split(',')?.length > 0 ? <>
                                    <ul >
                                        {blogsData?.blog_category_name?.split(',').map((item, index) => {
                                            return (<>
                                                <li key={index}><a href="javascript:void(0)">{item}</a></li>
                                            </>)
                                        })}
                                    </ul>
                                </> : null}

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src={blogsData.blog_image ? blogimgUrl + blogsData.blog_image : constant.DEFAULT_IMAGE} className="img-fluid"></img>
                        </div>
                    </div>
                </div>
            </div>
                <div className="mt-5" dangerouslySetInnerHTML={{ __html: blogsData.blog_desc }}></div></>}
            {relatedblogsList && relatedblogsList.length > 0 ? <>
                <section className="section-gap-medium">
                    <div className="container">
                        <div className="section-title tx-center">
                            <h2>Related Articles</h2>
                        </div>
                        <div className="row">
                            {relatedblogsList && relatedblogsList.map((item, index) => {
                                return (<>
                                    <div className="col-lg-4" key={index}>
                                        <div >
                                            <a href={`/blog/${item.blog_slug}`}>

                                                <div >
                                                    <img src={`${blogimgUrl + item.blog_image}`}></img>
                                                </div>

                                                <div>
                                                    <h6 className="title">{item?.blog_category_name}</h6>
                                                    <p className="tdec">{item?.blog_name}</p>
                                                    <p><a className="btn btn-underline-primary p-0" href={`/blog/${item.blog_slug}`}  >Read More</a></p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </>)
                            })}

                        </div>

                        <div className="row tx-center">
                            <div className="col-lg-12">
                                <button class="btn btn-primary-outline wd-250 mt-5" type="button" onClick={() => { navigate('/blog') }}>View Articles</button>
                            </div>
                        </div>
                    </div>
                </section>
            </> : ''}
            <Footer></Footer>


        </BrowserView>
        <MobileView>


            <Header innerHeader={'innerHeader'}></Header>
            {isLoading ? <>
                <div >
                    <div className="container-fluid px-0">
                        <div className="row g-0 align-items-center">
                            <div className="col-lg-6">
                                <div >
                                    <h1><Skeleton width={'150px'}></Skeleton></h1>
                                    <ul>

                                        {[...Array(2)].map((item, index) => {
                                            return (<>
                                                <li key={index} ><a href="javascript:void(0)"><Skeleton width={'100px'}></Skeleton></a></li>
                                            </>)
                                        })}

                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <Skeleton width={'700px'} height={'600px'}></Skeleton>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5 text-center" ><Skeleton width={'700px'} height={'300px'}></Skeleton></div>
            </> : <> <div >
                <div className="container-fluid px-0">
                    <div className="row g-0 align-items-center">
                        <div className="col-lg-6">
                            <div >
                                <h1>{blogsData.blog_name}</h1>

                                {blogsData.blog_category_name && blogsData?.blog_category_name?.split(',')?.length > 0 ? <>
                                    <ul >
                                        {blogsData?.blog_category_name?.split(',').map((item, index) => {
                                            return (<>
                                                <li key={index}><a href="javascript:void(0)">{item}</a></li>
                                            </>)
                                        })}
                                    </ul>
                                </> : null}

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src={blogsData.blog_image ? blogimgUrl + blogsData.blog_image : constant.DEFAULT_IMAGE} className="img-fluid"></img>
                        </div>
                    </div>
                </div>
            </div>
                <div className="mt-5 p-3" dangerouslySetInnerHTML={{ __html: blogsData.blog_desc }}></div></>}

            {relatedblogsList && relatedblogsList.length > 0 ? <>
                <section className="section-gap-medium">
                    <div className="container">
                        <div className="section-title tx-center">
                            <h2>Related Articles</h2>
                        </div>
                        <div className="row">
                            {relatedblogsList && relatedblogsList.map((item, index) => {
                                return (<>
                                    <div className="col-lg-4" key={index}>
                                        <div >
                                            <a href={`/blog/${item.blog_slug}`}>

                                                <div >
                                                    <img src={`${blogimgUrl + item.blog_image}`}></img>
                                                </div>

                                                <div >
                                                    <h6 >{item?.blog_category_name}</h6>
                                                    <p >{item?.blog_name}</p>
                                                    <p><a className="btn btn-underline-primary p-0" href={`/blog/${item.blog_slug}`}  >Read More</a></p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </>)
                            })}

                        </div>

                        <div className="row tx-center">
                            <div className="col-lg-12">
                                <button class="btn btn-primary-outline wd-250 mt-5" type="button" onClick={() => { navigate('/blog') }}>View Articles</button>
                            </div>
                        </div>
                    </div>
                </section>
            </> : ''}
            <Footer></Footer>
        </MobileView>

    </>)
}

export default BlogDetail