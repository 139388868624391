
import { useNavigate, useParams } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import React, { useContext, useState, useEffect, useRef } from 'react';
import DataContext from "../../Components/Elements/context";
import { ApiService } from "../../Components/Services/apiservices";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

const ResetPassword=()=>{
    const contextValues = useContext(DataContext)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id')
    const didMountRef = useRef(true)
    const [step, setStep] = useState(1)
    const [buttonLoader, setButtonLoader] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [resetPasswordData, setResetPassword] = useState({
        user_password: "",
        user_confirm_password: ""
    })

    const resetPasswordProcess = () => {
        let counter = 0;
        const myElements = document.getElementsByClassName("passwordRequired");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === '') {
                myElements[i].style.border = '1px solid red';
                counter++;
            } else {
                myElements[i].style.border = '';
            }
        }
        if (counter == 0) {
            if (resetPasswordData.user_confirm_password.trim() !== resetPasswordData.user_password.trim()) {
                setErrorMessage('The password and confirmation password does not match');
                return false;
            }
            const dataString = {
                user_password: resetPasswordData.user_password,
                user_confirm_password: resetPasswordData.user_confirm_password,
                token: id,
                user_type:1
            }
            setButtonLoader(true)
            ApiService.loginProccessPostData("userchangepassword", dataString).then((res) => {
                if (res.status == "success") {
                    setSuccessMessage(res.message);
                    setTimeout(() => {
                        setStep(2)
                        setButtonLoader(false)
                    }, 500)
                } else {
                    setButtonLoader(false)
                    setErrorMessage(res.message);
                }
            })
        }
    }
    const handleForgetPasswordChange = (e) => {
        const { name, value } = e.target;
        setResetPassword(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
        if (value !== '') {
            e.target.style.border = '';
        }
    };
    const navigate = useNavigate()
    return(<>
    <Header></Header>
    <section className="section-gap-medium">
            <div className="container">
                <div className="row justify-content-center">
                    {step==1 && ( <div className="col-lg-6">
                        <div className="section-title text-center mb-40">
                            <h2>Reset Password</h2>
                        </div>
                       
                        <div className="loginbox mb-20">
                        {errorMessage ? <Alert key={'danger'} className='danger' variant='danger'>{errorMessage}</Alert> : ''}
                        {successMessage ? <Alert key={'success'} className='success' variant='success'>{successMessage}</Alert> : ''}
                             
                            <div class="form-floating mb-3">
                                <input type="password" className="form-control passwordRequired" id="floatingPassword" placeholder="Password"  name='user_password' onChange={(e) => { handleForgetPasswordChange(e) }}/>
                                <label for="floatingPassword">Password</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="password" className="form-control passwordRequired" id="floatingPassword" placeholder="Confirm Password"  name='user_confirm_password' onChange={(e) => { handleForgetPasswordChange(e) }}/>
                                <label for="floatingPassword">Confirm Password</label>
                            </div>
                            <button type="button" className="btn btn-primary btn-full btn-lg"onClick={(e) => { resetPasswordProcess() }} disabled={buttonLoader}> {buttonLoader ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : ("CHANGE")}</button>
                           
                        </div>
                      
                    </div>)}
                  
                    {step==2 && ( <div className="col-lg-6">
                        
                       
                        <div className="loginbox mb-20">
                        <div className="text-center mb-20">
                                    <img src='/img/verified.gif' className="wd-200 mb-20" />
                                    <h2 style={{ textAlign: 'center' }}>Thank you for submitting </h2>
                                    <p style={{ textAlign: 'center' }}>Your Password has been reset</p>
                                </div>
                                <button className="btn btn-lg btn-primary btn-full mb-20 mt-20" onClick={() => { navigate('/') }}> Go to Home</button>
                                <div className="text-center">
                                    <p>Already have an account?</p>
                                    <a className="btn btn-underline-primary p-0" href="/login">Sign in</a>
                                </div>
                        </div>
                      
                    </div>)}
                   
                </div>
            </div>
        </section>
    <Footer></Footer>
    </>)
}


export default ResetPassword