import Footer from "../../Components/Footer"
import Header from "../../Components/Header"

const BookAppointment=()=>{
    return(<>
    <Header></Header>
    <section className="section-gap-medium">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className="row g-3">
                        <div className="col-lg-6">
                            <div className="mb-20">
                            <h4 className="tx-primary">NEW YORK, USA</h4>
                            <p className="text-uppercase tx-18">New York Flagship Store</p>
                            <a href="#" class="linebutton">SEE THIS STORE</a>
                            </div>
                           
                        </div>
                        <div className="col-lg-6">
                            <h4 className="tx-primary">NEW YORK, USA</h4>
                            <p className="text-uppercase tx-18">New York Flagship Store</p>
                            <a href="#" class="linebutton">SEE THIS STORE</a>
                        </div>
                        <div className="col-lg-6">
                            <h4 className="tx-primary">NEW YORK, USA</h4>
                            <p className="text-uppercase tx-18">New York Flagship Store</p>
                            <a href="#" class="linebutton">SEE THIS STORE</a>
                        </div>
                        <div className="col-lg-6">
                            <h4 className="tx-primary">NEW YORK, USA</h4>
                            <p className="text-uppercase tx-18">New York Flagship Store</p>
                            <a href="#" class="linebutton">SEE THIS STORE</a>
                        </div>
                       
                      
                    </div>
                    
                </div>
                <div className="col-lg-6">
                    <div className="bookapponimentform">
                <div className='section-title mb-40'>
                            <h2>Book Appointment</h2>
                        </div>
                        <div className="row g-3">
                            <div className="col-lg-12">
                            <div className='form-group'>
                            <label>Store</label>
                            <select></select>
                        </div>
                            </div>
                            <div className="col-lg-12">
                            <div className='form-group'>
                            <label>Name</label>
                            <input type='text'></input>
                        </div>
                            </div>
                            <div className="col-lg-6">
                            <div className='form-group'>
                            <label>Email</label>
                            <input type='email'></input>
                        </div>
                            </div>
                            <div className="col-lg-6">
                            <div className='form-group'>
                            <label>Phone</label>
                            <input type='email'></input>
                        </div>
                            </div>
                            <div className="col-lg-12">
                        <div className='form-group'>
                            <label>Message</label>
                            <textarea type='text' rows={4}></textarea>
                        </div>
                        </div>
                        <div className="col-lg-12">
                        <button type="button" class="btn btn-primary btn-full btn-lg">Submit</button>
                        
                        </div>
                        </div>
                   </div>
                    </div>
                </div>
            
        </div>
    </section>
    
    <Footer></Footer>
    </>)
}

export default BookAppointment